import React from "react"

export default function Copyright() {
    return (
        <div className="copyrightPage">
        <div className="copyrightPageDiv">
                <div className="contactPageDivText1">Copyright Notice</div>
                    
                <div className="contactPageDivText">© 2024 Assa. All rights reserved.</div>

                <div className="contactPageDivText">All content on this website, including text, images, and interactive elements, is the property of Assa.</div>

                <div className="contactPageDivText">Unauthorized use, reproduction, or distribution of this material is strictly prohibited.</div>
        </div>
    </div>
    )
}