import React, {useEffect, useState} from "react"
import axios, { Axios } from 'axios'

export default function ChangeEmailSettings() {

    const [emailReg, setEmailReg] = useState('')
    const [emailError, setEmailError] = useState('');

    const[emailStatus, setEmailStatus] = useState('')

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')

    const validateEmail = (value) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!regex.test(value)) {
            setEmailError('Invalid email format');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const changeEmailFunction =() => {
        
        axios.post(`${process.env.REACT_APP_BACKEND}/changeEmail`, {
            email: emailReg
        }).then((response) => {
            //alert(response.data.message)

            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))

            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
      }

      const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setDescription('')
    }


    return (
        <div className="changeEmailPage">
             <form className="formAccountChangeEmail">  
             <div className="changeEmailFormColumn">
                <label className="labelChangeEmail">Write new email</label>

                <input type="text" className="buttonForm"
                 onChange={(e) => {
                    setEmailReg(e.target.value)
                    validateEmail(e.target.value);
                }}/>
                {emailError && <span className="errorChangeEmail">{emailError}</span>}
            </div>

                <button type="button" onClick={() => {changeEmailFunction()}} className="buttonFormSubmitChangeEmail" disabled={emailError}>Change email</button>
            </form>

            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
            <div className="alertDiv-content">
                <i className="check">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Success</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            <div className="progressDiv active"></div>
            </div>

            <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>

            <div className="alertDiv-content">
                <i className="checkWrong">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Ops</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            <div className="progressDiv active"></div>
            </div>
        </div>
    )
}