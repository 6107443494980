import React, {useEffect, useRef, useState} from "react"
import { useParams } from 'react-router-dom';
import axios from 'axios'

export default function VerifyEmailPage() {

  axios.defaults.withCredentials = true

  const [verificationStatus, setVerificationStatus] = useState('Verifying email...');
  const [verificationStatusState, setVerificationStatusState] = useState(0);

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
  
    if (!code) {
      throw new Error('Verification code not found');
    }
  
    //console.log('Verification code:', code);
  
    const url = `${process.env.REACT_APP_BACKEND}/api/verify-email?code=${code}`;
    //console.log('Request URL:', url);
  
    axios.get(url)
      .then((response) => {
        if (response.data && response.data.success) {
          setVerificationStatus('Email successfully verified');
          setVerificationStatusState(0)
          sendVerificationStateFunction(code)
        } else {
          throw new Error(response.data.error || 'An error occurred while verifying email');
        }
      })
      .catch((error) => {
        console.error('Error verifying email:', error);
        setVerificationStatus('An error occurred while verifying email');
        setVerificationStatusState(1)
      });
  };

  const linkToLogin =() => {
    window.location.href = `${process.env.REACT_APP_BACKEND_TWO}/register`;
  }

  const sendVerificationStateFunction =(codeFromLink) => {
    //console.log('HERE CODE', codeFromLink)
    axios.post(`${process.env.REACT_APP_BACKEND}/sendVerificationState`, {
        state: 'verified',
        codeFromLink: codeFromLink
    }).then((response) => {
        //console.log(response)
        //alert(response.data.message)
    }).catch((err) => {
        //console.log(err.message)
        //alert(JSON.stringify(err.response.data.message))
    })
    
}
  
    return (
        <div className="verifyPage">
            <div className={verificationStatusState === 0 ? "verifyPageText" : "verifyPageTextRed"}>{verificationStatus}</div>
            <div className="verifyPageGoToLogin" onClick={() => {linkToLogin()}}>Go to the login page</div>
        </div>
    )
}