import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text }) => {
  //console.log('texttt', text)
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const pitch =  1;
  const rate= 0.8;
  const volume = 1;

  useEffect(() => {
    //console.log('useEffect called with text:', text);

    //const synth = window.speechSynthesis;

    //console.log('SYNTH', window.speechSynthesis);

    let synth; 
    const handleVoicesChanged = () => {

      synth = window.speechSynthesis;

      //console.log('SYNTH', window.speechSynthesis);

      //const voices = synth.getVoices();
      //console.log('THE VOICES IS This special function', voices);


 


    const u = new SpeechSynthesisUtterance(text);
    setUtterance(u);

    {
      //console.log('I am here')
      const voices = synth.getVoices();

      let found = false;

      const desired_voice = "Google\u00a0普通话（中国大陆）"
      const desired_voice2 = "Microsoft\u00a0Xiaoxiao\u00a0Online\u00a0(Natural)\u00a0-\u00a0Chinese\u00a0(Mainland)"
      const desired_voice3 = "Microsoft Xiaoxiao Online (Natural) - Chinese (Mainland)"
      
      //console.log('hi')
      //console.log('VOICES', voices)
      //console.log('VOICES LENGTH', voices.length)

      for(let i=0;i<voices.length;i++) {
        //console.log('I am here desired voice 1', voices[i].name)
        if(voices[i].lang !== 'zh-CN')
          continue
        if(voices[i].name === desired_voice){
          //console.log('I am here desired voice 2', voices[i].name)
          setVoice(voices[i])
          found = true;
        }else if (voices[i].name === desired_voice2){
          //console.log('I am here desired voice 2', voices[i].name)
          setVoice(voices[i])
          found = true;
        } else if (voices[i].name === desired_voice3){
          //console.log('I am here desired voice 2', voices[i].name)
          setVoice(voices[i])
          found = true;
        }
      }

      if(!found){
        //console.log('not found')
        for(let i=0;i<voices.length;i++) {
          if(voices[i].lang !== 'zh-CN')
            continue
          setVoice(voices[i])
          //console.log('voice[i]', voices[i])
          found = true
          break
        }
      }
      

      if(!found){
          //console.log('voices 0')
            setVoice(voices[0])
    }
    }

  };

    if (window.speechSynthesis) {
      //console.log('I run')
      synth = window.speechSynthesis; // Initialize synth if available
      setTimeout(() => {
        handleVoicesChanged(); // Call after a short delay to ensure voices are available
      }, 100);
      //synth.removeEventListener("voiceschanged", handleVoicesChanged); 
      //synth.addEventListener("voiceschanged", handleVoicesChanged);   
    } 

    // Add an event listener to the speechSynthesis object to listen for the voiceschanged event
    synth.addEventListener("voiceschanged", () => {
      const voices = synth.getVoices();
    });

    return () => {
      synth.cancel();
      //console.log('here')
      synth.removeEventListener("voiceschanged", () => {
        setVoice(null);
      });
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

      //console.log('VOICE IS handle play this', voice)
      utterance.voice = voice;
      utterance.pitch = pitch;
      utterance.rate = rate;
      utterance.volume = volume;
      synth.speak(utterance);

  };

  const handleVoiceChange = (event) => {
    //console.log('The value is', event.target.value)
    const voices = window.speechSynthesis.getVoices();
    //console.log('THE VOICES IS', voices)
    const voices2 = window.speechSynthesis;
    //console.log('THE VOICES IS 2', voices2)
    //alert(voices[0].lang)
    setVoice(voices.find((v) => v.name === event.target.value));
    //console.log('the handle voice', voice)
  };
  

  return (
      <div className="audioSVG" onClick={handlePlay}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#808080" width="60px" height="60px" viewBox="-1.5 0 19 19" className="cf-icon-svg"><path d="M7.098 4.769v9.63c0 .61-.353.756-.784.325L3.42 11.828H1.442A1.112 1.112 0 0 1 .333 10.72V8.448A1.112 1.112 0 0 1 1.442 7.34h1.977l2.895-2.896c.431-.43.784-.285.784.325zm2.076 7.474a.553.553 0 0 0 .392-.163 3.53 3.53 0 0 0 0-4.992.554.554 0 1 0-.784.784 2.422 2.422 0 0 1 0 3.425.554.554 0 0 0 .392.946zm2.184 1.629a6.059 6.059 0 0 0 0-8.575.554.554 0 0 0-.784.783 4.955 4.955 0 0 1 0 7.008.554.554 0 0 0 .784.784zm1.79 1.79a8.59 8.59 0 0 0 0-12.157.554.554 0 0 0-.783.784 7.481 7.481 0 0 1 0 10.59.554.554 0 1 0 .784.784z"/></svg>
      </div>
  );
};

export default TextToSpeech;