import React, { useEffect, useState } from "react"
import axios from 'axios'

export default function List() {

    
    const [data, setData] = useState([])

    const getCharactersData =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/quizHSK2/getCharactersForList`).then((response) => {
            if (response.data.characters){
                setData(response.data.characters)
                //console.log('data is', response.data.characters)
                //console.log(response.data.characters)
            } 
        });
     }
    
     useEffect(() => {
        getCharactersData()
    }, []);

const CharactersList = ({character, index}) => {

    const [score, setScore] = useState(0)
    const [maxQuestions, setMaxQuestions] = useState(0)
    const [hint, setHint] = useState(0)

    useEffect(() => {
        
        const generalCharacterResult = character.character
        //console.log(generalCharacterResult)
        generalScore()
        amountOfAnsweredCharactersFunction()
    }, [character]);

    const generalScore =() => {
        //console.log(character.character.character)
        const generalCharacterResult = character.character
        const url = `${process.env.REACT_APP_BACKEND}/generalScoreForOneCharacter?generalCharacterResult=${generalCharacterResult}`;
        
        //console.log('url', url)
        axios.get(url).then((response) => {
            //setPost(response.data);
            if (response.data.amount){
                setMaxQuestions(response.data.amount)
            } 
        });       
    }

    const amountOfAnsweredCharactersFunction =() => {
    const url = `${process.env.REACT_APP_BACKEND}/amountOfAnsweredCharacters?characterId=${character.id}&character=${character.character}`;
        
    axios.get(url).then((response) => {
        //setPost(response.data);
        //alert(JSON.stringify(response.data))
        if (response.data.amountOfCharacters){
            setScore(response.data.amountOfCharacters)
        } 
    });
 }

    //console.log('maxQuestions is', maxQuestions)
    //console.log(character.character.character)
    //console.log('score is', score)
    const percentage = score / maxQuestions
    //console.log('percentage is', percentage)  

    return (
           <div className="descriptionList">
                <p className="mainWordList">{character.character}</p>
                <p className="translationWordList">{character.translation}</p>
                
                <p className="radicalsDescriptionList">{character.hintsRadicals}</p>
                <p className="radicalsTranslationList">{character.hintsRadicalTranslations}</p>
                <p className="wordDescriptionList">{character.info}</p>
                <br/>
                <div className="buttonListBox">
                    <div className={percentage ? (percentage === 1 ? "buttonList" : "buttonList2") : "buttonListBox"} style={{height: percentage ? `${percentage * 50}px` : '50px'}}></div>
                </div>

            </div>
      )
}

    return (
        <>
        { data.length != 0 ? 
        <div className="list">
             {
                            data.length != 0 ? (data.map((character, index) => {
                                return <CharactersList character={character} index={index} key={index}/>
                            }))
                            : <div></div>
            }           
        </div>
        :
        <div className="main"> 
        <div className="waiting">
            <button className="threeDotsWaiting" id="id-three-dots4">
                <div className="blackDotWaiting"></div>
                <div className="blackDotWaiting"></div>
                <div className="blackDotWaiting"></div>
            </button>
        </div>
        </div>
        }
        </>
    )  
}