import React, {useEffect, useState} from "react"
import axios, { Axios } from 'axios'

export default function ChangePasswordSettings() {
    const [passwordReg1, setPasswordReg1] = useState('')
    const [passwordReg2, setPasswordReg2] = useState('')
    const [passwordReg3, setPasswordReg3] = useState('')
    const [passwordError2, setPasswordError2] = useState('');
    const [passwordError3, setPasswordError3] = useState('');

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')

    const validatePassword2 = (value) => {
        if (value.length > 30) {
            setPasswordError2('Password must be maximum 30 characters long');
            return false; // Password longer than 30 characters
        } else if (value.includes('Password cannot be empty')) {
            setPasswordError2('Password cannot contain spaces');
            return false;
        } else if (value.length < 8) {
            setPasswordError2('Password must be at least 8 characters long');
            return false;
        } else {
            setPasswordError2('');
            return true;
        }
    };

    const validatePassword3 = (value) => {
        if (value.length > 30) {
            setPasswordError3('Password must be maximum 30 characters long');
            return false; // Password longer than 30 characters
        } else if (value.includes(' ')) {
            setPasswordError3('Password cannot contain spaces');
            return false;
        } else if (value.length < 8) {
            setPasswordError3('Password must be at least 8 characters long');
            return false;
        } else {
            setPasswordError3('');
            return true;
        }
    };

    const changePasswordFunction =() => {
        
        axios.post(`${process.env.REACT_APP_BACKEND}/changePassword`, {
            passwordOld: passwordReg1,
            passwordNew: passwordReg2,
            passwordNewRepeat: passwordReg3
        }).then((response) => {
            //alert(response.data.message)

            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))

            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
        })
      }

      const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setDescription('')
    }

    const restorePasswordLink =() => {
        window.location.href = `/restorepasswordrequest`;
      }
      
    return (
        <div className="changePasswordPage">
             <form className="formAccountChangePassword">  
             <div className="changeEmailFormColumn">
                <label className="labelChangePassword">Old password</label>

                <input type="password" className="buttonFormChangePassword"
                 onChange={(e) => {
                    setPasswordReg1(e.target.value)
                }}/>
            </div>

            <div className="changeEmailFormColumn">
                <label className="labelChangePassword">New password</label>

                <input type="password" className="buttonFormChangePassword"
                 onChange={(e) => {
                    setPasswordReg2(e.target.value)
                    validatePassword2(e.target.value);
                }}/>
                {passwordError2 && <span className="errorChangeEmail">{passwordError2}</span>}
            </div>

            <div className="changeEmailFormColumn">
                <label className="labelChangePassword">Repeat new password</label>

                <input type="password" className="buttonFormChangePassword"
                 onChange={(e) => {
                    setPasswordReg3(e.target.value)
                    validatePassword3(e.target.value);
                }}/>
                {passwordError3 && <span className="errorChangeEmail">{passwordError3}</span>}
            </div>

                <button type="button" onClick={() => {changePasswordFunction()}} className="buttonFormSubmitChangeEmail" disabled={passwordError2 || passwordError3}>Change password</button>
            </form>

            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
            <div className="alertDiv-content">
                <i className="check">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Success</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            </div>

            <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>

            <div className="alertDiv-content">
                <i className="checkWrong">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDiv">
                <span className="textDiv textDiv-1">Ops</span>
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>

            </div>
            <div className="recoverPasswordChangePasswordPage">Forgot password? You can <span className="recoverPasswordChangePasswordPageSpan" onClick={() => {restorePasswordLink()}}>restore</span> it with your email</div>
        </div>
    )
}