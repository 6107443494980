import React, { useEffect, useState, useContext } from "react"
import io from "socket.io-client"
import Chat from "./Chat"
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { CodeContext } from "../context/Context";
import { FriendNameContext } from "../context/ContextFriendName";
import NotFoundPage from "./NotFoundPage";


const socket = io.connect(`${process.env.REACT_APP_BACKEND.replace('/api', '')}`, {
    path: '/socket.io',
    transports: ['websocket'],
    secure: false,  // Set to true if using HTTPS
});


export default function JoinChat() { 
    /*useEffect(() => {
        console.log('Received props:', { roomNumber });
      }, [roomNumber]);*/
    const [username, setUsername] = useState("")
    const [showChat, setShowChat] = useState(false)

    const {code, setCode} = useContext(CodeContext)
    const {friendNameFromContext, setFriendNameFromContext} = useContext(FriendNameContext)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    //const initialRoomNumber = queryParams.get('room');
    //const initialFriendName = queryParams.get('friendName');
    //const [room, setRoom] = useState(initialRoomNumber || '');
    //const [friendName, setFriendName] = useState(initialFriendName || '');
    //console.log('Room number from link', room)
    //console.log('FriendName from link', friendName);

    //const initialFriendName = queryParams.get('friendName');
    //const friendName = initialFriendName;
    const friendName = friendNameFromContext;

    const [chatExists, setChatExists] = useState('loading');

    //console.log('friend name from context', friendNameFromContext)

    //console.log('the friend name join chat page is', friendName)
    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')
    
    const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
    }

    useEffect(() => {
        //const searchParams = new URLSearchParams(location.search);
        //const roomTemp = searchParams.get('room');

        //console.log(code)
        /*if(roomTemp) {
            setRoom(roomTemp)
        }*/
            
        async function fetchData() {
            const ownidData = await findOwnName(); 
            //setOwnidData(ownidDataConst)                
            if(code) { 
                //console.log('I am here')
                //console.log('code is', code)
                axios.post(`${process.env.REACT_APP_BACKEND}/joinChatCode`, {
                    number: code, 
                }).then((response) => {
                    if(response.data.newChat) {
                        //console.log('here name', ownidData)
                        //console.log('hi from joinChatCode')
    
                        joinRoom(ownidData, code)
                        setChatExists('exists')
                        //console.log(response.data.message)
                        //alert(response.data.message)
                        setCode('')
                        setInfoToChat(infoToChat + 1)
                        //alert(response.data.message)
                        if (friendName){
                            //console.log('the friend name is', friendName)
                            sendInvitationPrivateChat(friendName)
                        }
                    } else if (response.data.message === `chat already exists for one user`){
                        //console.log('chat exists for one user')
                        joinRoom(ownidData, code)
                        setCode('')
                        setChatExists('exists')
                    } else if(response.data.message === `chat already exists`){
                        //console.log('chat already exists')
                        joinRoom(ownidData, code)
                        setCode('')
                        setChatExists('exists')
                    }
                    
                }).catch((err) => {
                    //console.log(err.message)
                    setAlertStateWrong(1);
                    const descrWrong = err.response.data.message
                    setDescription(descrWrong)
                    setTimeout(() => {
                        setAlertStateWrong(0);
                        setDescription('')
                      }, 5000);
                    //alert(JSON.stringify(err.response.data.message))
                })
            } 
           else{
                //console.log('no code')
                const queryParamsQuestion = new URLSearchParams(window.location.search);
                //console.log("Query parameters:", queryParamsQuestion.toString());
                //console.log("true/false", queryParamsQuestion.has('room'))
                const parameterNames = [...queryParamsQuestion.keys()];
                //console.log("Parameter names length", parameterNames.length);
                if (queryParamsQuestion.has('room') && parameterNames.length === 1 && parameterNames[0] === 'room') {
                
                const initialRoomNumber = queryParams.get('room');
    
                const initialFriendName = queryParams.get('friendName');
        
                //console.log('initial room number', initialRoomNumber)
                //console.log('initial friend name', initialFriendName)
                const checkIfChatExists = async (initialRoomNumber) => {
                    //console.log('hi from checkIfChatExists')
                    const ownid = await findOwnName()
                    //console.log('checkIfChatExists', initialRoomNumber)
                    const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExists?roomNumber=${initialRoomNumber}`;
                    //console.log('url is', url)
                    axios.get(url).then((response) => {
                      //console.log('Full axios response chats', response) 
                        if (response.data.specialMessage && response.data.specialMessage){
                                //console.log('Receiving data chat', response.data.chat)
                                if (response.data.specialMessage == 'chatExists'){
                                    //console.log('ownid is', ownid)
                                    joinRoom(ownid, initialRoomNumber)
                                    setCode('')
                                    setChatExists('exists')
                                    //console.log('exists', response.data.specialMessage)
                                }else{
                                    setCode('')
                                    setChatExists("doesn't exist")
                                    //console.log(response.data.specialMessage)
                                }
                        }
                    }) 
                    .catch((error) => {
                      console.error('Error', error.message)
                    })
                } 
    
                checkIfChatExists(initialRoomNumber)

                } else {
                    setChatExists("doesn't exist")
                    }
            
            }
            axios.get(`${process.env.REACT_APP_BACKEND}/userdataAuth`).then((response) => {
                
                //setPost(response.data);
                //alert(response.data.username)
                if (response.data.username){
                    setUsername(response.data.username)
                    //joinRoom(response.data.username, (room === '' || room === undefined) ? initialRoomNumber : room)
                    //joinRoom(response.data.username, code)
                } 
            }).catch(err => {
                alert(err.message)
            });
        }
    
        fetchData();
        
    
    }, [location.search])

    
    const findOwnName =async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/userdataAuth`);
            
            if (response.data.username) {
                //console.log('find own name', response.data.username)
                return response.data.username
                // joinRoom(response.data.username, (room === '' || room === undefined) ? initialRoomNumber : room);
                // joinRoom(response.data.username, code);
            }
        } catch (err) {
            alert(err.message);
        }
    }

    axios.defaults.withCredentials = true

    const Data =(code) => {

        //console.log('it works')
        if (code) {
            //console.log('I set it', room)
        } else{
            axios.get(`${process.env.REACT_APP_BACKEND}/userdataAuth`).then((response) => {
                
                //setPost(response.data);
                //alert(response.data.username)
                if (response.data.username){
                    setUsername(response.data.username)
                    //joinRoom(response.data.username, (room === '' || room === undefined) ? initialRoomNumber : room)
                    joinRoom(response.data.username, code)
                } 
            }).catch(err => {
                alert(err.message)
            });
        }
        
    }

    const Code =() => {

        //console.log('THE ROOM IS ', code)

        axios.post(`${process.env.REACT_APP_BACKEND}/joinChatCode`, {
            number: code, 
        }).then((response) => {
            if(response.data.newChat) {
                //console.log(response)
                //alert(response.data.message)
                setInfoToChat(1)
                if (friendName){
                    
                    //console.log('the friend name is 2', friendName)
                    sendInvitationPrivateChat(friendName)
                }
            }
            
        }).catch((err) => {
            //console.log(err.message)
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
            //alert(JSON.stringify(err.response.data.message))
        })               
    }

    const sendInvitationPrivateChatExtraFunction =(friendName, chatLink) => {
        //console.log('extra function')
        //console.log('the friend name is extra', friendName)
        //console.log('the chatLink is extra', chatLink)
        axios.post(`${process.env.REACT_APP_BACKEND}/sendInvitationPrivateChatExtra`, {
            name: friendName, 
            number: chatLink
        }).then((response) => {
            //console.log(response)
            //alert(response.data.message)
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }

    /*const generateRandomString = () => {
        setCode(Math.floor(Math.random() * Date.now()).toString(36));
        //console.log(room)
      };*/

      const [infoToChat, setInfoToChat] = useState(0);
      
    const joinRoom = (username, code) => {
        //console.log('username', username)
        //console.log('code', code)
        if (username !== "" && code !== "") {
            socket.emit("join_room", code)
        }
    }

    const sendInvitationPrivateChat =(friendName) => {
        //console.log('NAME JOIN CHAT CODE ////////////////', friendName)
        const name = friendName
        //console.log('NUMBER JOIN CHAT CODE ////////////////////', code)
        axios.post(`${process.env.REACT_APP_BACKEND}/sendInvitation`, {
            name: name, 
            number: code
        }).then((response) => {
            //console.log(response)
            //console.log('I come back')
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            if (friendName){
                //console.log('sending extra invitation')
                sendInvitationPrivateChatExtraFunction(friendName, code)
              }
            //console.log(err.message)
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const hasRoom = queryParams.has('room');
  
      const hasFriendName = queryParams.has('friendName');
      if(hasRoom && hasFriendName && code === ''){
        queryParams.delete('friendName'); 
        const newSearch = queryParams.toString(); 
        const newUrl = `${location.pathname}?${decodeURIComponent(newSearch)}`; 

        window.location.replace(newUrl); 
      }
    }, [location.search])

    return (
        <>
        {chatExists === 'exists' ? 
        <div className="chat">
            <div className="chatPage">
                {username !== "no username" ? <Chat socket={socket} username={username} codeChat={code} infoToChat={infoToChat}/> : <></>}

            </div>
            
           
            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
    
                    <div className="alertDiv-content">
                        <i className="check">

                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                        <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </i>

                        <div className="messageDiv">
                        <span className="textDiv textDiv-1">Success</span>
                        <span className="textDiv textDiv-2">{description}</span>
                        </div>
                    </div>
                    <button className="close" onClick={() => {closeAlert()}}>X</button>

                    <div className="progressDiv active"></div>
            </div>

            <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>

                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Ops</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
            </div>
       
        </div> :  
        (chatExists === "doesn't exist" ? <NotFoundPage /> : <><div className="main"></div></>)            
        }
       </>
    )
}