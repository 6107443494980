import React from "react"

export default function Contact() {
    return (
        <div className="contactPage">
            <div className="contactPageDiv">
            <p>If you want to contact us please email us at chinese.app@gmail.com </p>
            <p>We will be glad to help you</p>
            <p>Best regards,</p>
            <p>Assa</p>
            </div>
        </div>
    )
}