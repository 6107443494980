import React, { useState } from 'react';
import axios from 'axios';

export default function Paypal() {

        const [cardNumber, setCardNumber] = useState('');
        const [expiryDate, setExpiryDate] = useState('');
        const [cvv, setCvv] = useState('');
      
       const handlePayment = async () => {
          /*try {
            const response = await axios.post('${process.env.REACT_APP_BACKEND}/paypal/payment', {
              cardNumber,
              expiryDate,
              cvv
            });
            console.log(response.data);
            // Handle success
          } catch (error) {
            console.error(error);
            // Handle error
          }*/
        };
    return (
        <div className="contactPage">
        <div className="contactPageDiv">
        <div className="aboutPageColumn">
            <div>
                <input type="text" placeholder="Card Number" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} />
                <input type="text" placeholder="Expiry Date" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                <input type="text" placeholder="CVV" value={cvv} onChange={(e) => setCvv(e.target.value)} />
                <button onClick={handlePayment}>Pay with Credit Card</button>
            </div>
        </div>

        </div>
    </div>
    )
}