import React, { useState, useEffect } from "react" ;
import axios from 'axios'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PaypalCheckout = () => {
    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_FRONTEND
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    const [formattedDate, setFormattedDate] = useState('');
    const [message, setMessage] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Course",
                    amount: {
                        currency_code: "EUR",
                        value: 10,
                    },
                },
            ],
            application_context: {
                brand_name: "Assa App",
                locale: "en-US",
                user_action: "PAY_NOW",
                shipping_preference: "NO_SHIPPING"
            }
        }).then((orderID) => {
            setOrderID(orderID);
            return orderID;
        });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            setSuccess(true);
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };

    useEffect(() => {
        if (success) {
            setMessage("Your payment succeeded")
            setIsDisabled(true);
            //alert("Payment successful!!");
            //console.log('Order successful . Your order id is--', orderID);
            const fetchPaymentAndAnother = async () => {
                try {
                  const response = await axios.get(`${process.env.REACT_APP_BACKEND}/setThePaymentDate`);
                  
                  //console.log('theExpirationDate', response.data.expirationDate)
                  if (response.data.expirationDate) {
                    const theExpirationDate = response.data.expirationDate;
                    //console.log('theExpirationDate', response.data.expirationDate)
                    
                    const formatDate = (dateString) => {
                      // Convert the date string to a Date object
                      const date = new Date(dateString);
                      
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                      const day = String(date.getDate()).padStart(2, '0');
                      
                      return `${year}.${month}.${day}`;
                    };
              
                    // Format the expiration date
                    const formattedDateConst = formatDate(theExpirationDate);
                    setFormattedDate(formattedDateConst);
                    //console.log('theExpirationDate formatted', formattedDateConst);

                    await axios.post(`${process.env.REACT_APP_BACKEND}/sendPaymentVerifyEmail`, 
                        {
                            expirationDate: formattedDateConst
                        });
                  } else {
                    //console.log('Mistake in setting date')
                  }
                } catch (error) {
                  console.error('Error', error.message);
                }
              };
              
              // Call the async function
              fetchPaymentAndAnother();
            }
    },[success]);

    return (
        <div className="wrapper">
        <PayPalScriptProvider options={{ "client-id": CLIENT_ID, currency: "EUR" }}>
            <div className={`paypal-container ${isDisabled ? 'disabled' : ''}`}>
                    <PayPalButtons
                        style={{ 
                            layout: "vertical", 
                            shape:   'rect',
                            height: 40,
                        }}
                        className="buttonsPaypal"
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
            </div>
        </PayPalScriptProvider>
        {message && <div className="succededPaymentDivPaypal"><div className="succededPayment">{message}</div><div className="succededPaymentDate">Payment is valid till {formattedDate}</div></div>}
        </div>
    );
}

export default PaypalCheckout