import React, { useEffect, useState, useRef, useContext }  from "react"
import Courses from "../pages/Courses"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Chat from "../pages/Chat";
import io from "socket.io-client";
import axios from 'axios'
import JoinChat from "../pages/JoinChat";
import { useNavigate, useLocation } from "react-router-dom"

//import { ThemeContext } from "../context/Context";

import { CodeContext } from "../context/Context";

const socket = io.connect(`${process.env.REACT_APP_BACKEND.replace('/api', '')}`, {
  path: '/socket.io',
  transports: ['websocket'],
  secure: false,  // Set to true if using HTTPS
});

export default function Navbar() {

  const navigate = useNavigate();
  const location = useLocation();

  const [showNavBar, setShowNavBar] = useState(1)

  useEffect(() => {
    if(location.pathname === '/paying' || location.pathname === '/payingPage'){
      setShowNavBar(2)
    }else{
      setShowNavBar(1)
    }
  }, [location.search])

    useEffect(() => {
      //console.log('hi')
      displayFriend()
    }, []);

    /*const {theme, setTheme} = useContext(ThemeContext)
    const toggleTheme = () => {
      const newTheme = theme === 'light' ? 'dark' : 'light'
      setTheme(newTheme)
    }*/

    const {code, setCode} = useContext(CodeContext)

    const [notificationBox, setNotificationBox] = useState(0)
    const [friendNameList, setFriendNameList] = useState([])
    const [room, setRoom] = useState(0)
    const [roomNumber, setRoomNumber] = useState(0)
    const [name, setName] = useState(0)
    const [showChat, setShowChat] = useState(false);

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')

    axios.defaults.withCredentials = true
    
    const displayFriend = async () => {

      const paidCourse = await is_paid()
      if (paidCourse === 1){
        axios.get(`${process.env.REACT_APP_BACKEND}/displayFriendInvite`).then((response) => {
          //console.log('Full axios response friends', response) 
            if (response.data.friendNames && response.data.friendNames){
              //console.log('Receiving data friends', response.data.friendNames)
                setFriendNameList(response.data.friendNames)
                setRoomNumber(response.data.room)
            } else {
              console.log('No friend names received or invalid data structure')
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
      } 
  } 

  const is_paid = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/is_paid`);
      //setPost(response.data);
        //alert(JSON.stringify(response.data))
            //console.log(response.data.paid)
      if (response.data.paid === 1) {
        return 1
      } else if (response.data.paid === 0) {
        return 0
      }
  } catch (error) {
      console.error('Error fetching is_paid status:', error);
      throw error; // Optionally rethrow the error
  }
 }

  //console.log('List', JSON.stringify(friendNameList))
  //console.log('RoomNumber', JSON.stringify(roomNumber))
 

  const getOwnName = async (usernameFromLink, newInfoFromDatabase) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/getOwnName`);
        if (response.data.name) {
            //console.log('name is here',response.data.name)
            //setOwnName(response.data.name);
            return response.data.name
        }
    } catch (error) {
        console.error('Error fetching own name:', error);
    }
};

  const postChatNumber = async (name, roomNumber) => {
    const ownName = await getOwnName()
    //console.log('NUMBER', roomNumber)
    //console.log('Sending name:', name);
    axios.post(`${process.env.REACT_APP_BACKEND}/joinRoomInfo`, {
      name: name, 
      number: roomNumber
  }).then((response) => {
      //console.log('Response data', response.data.message)
      const userData = {
        room: roomNumber,
        ownName: ownName,
    };
      socket.emit("show_user", userData);
      linkToChat(roomNumber);
      removeInvitationFunction(name, roomNumber)
  }).catch((err) => {
      alert(JSON.stringify(err.response.data.message))
  })   

}

const removeInvitationFunction =(name, roomNumber) => {
  axios.post(`${process.env.REACT_APP_BACKEND}/removeInvitation`, {
      name: name,
      number: roomNumber
  }).then((response) => {
      //const descr = response.data.message
      //alert(descr)
      if (friendNameList.length === 1) {
        setNotificationBox(0)
      } 
      displayFriend()
      //setAlertState(1);
      //const descr = response.data.message
      //setDescription(descr)
      //setTimeout(() => {
          //setAlertState(0);
          //setDescription('')
      //}, 5000);
  }).catch((err) => {
    //alert(JSON.stringify(err.response.data.message))
    setAlertStateWrong(1);
    const descrWrong = err.response.data.message
    setDescription(descrWrong)
    setTimeout(() => {
        setAlertStateWrong(0);
        setDescription('')
    }, 5000);
  })
  
}

const closeAlert =() => {
  setAlertState(0)
  setAlertStateWrong(0)
  setDescription('')
}

/*const addToChatInfo = () => {
  alert('I START WORK 3')
  axios.get('${process.env.REACT_APP_BACKEND}/addToChatInfo').then((response) => {
    console.log('Full axios response addtochat', response) 
      if (response.data.number && response.data.name){
        console.log('Receiving data addtochat', response.data.number)
        alert('I START WORK 4')
          setRoom(response.data.number)
          setName(response.data.name)
      } else {
        console.log('No number received or invalid data structure')
      }
  }) 
  .catch((error) => {
    console.error('Error', error.message)
  })
  alert('I START WORK 5')
  
  alert('I START WORK 6')
} */

/*useEffect(() => {
  if (showChat) {
    alert('I START WORK 7')
    alert('Here is the room number', roomNumber)
    return <JoinChat roomNumber={roomNumber} />;
  }
}, [showChat]);*/

const linkToChat =(roomNumber) => {

  //console.log('Here is the room number', roomNumber)
  setCode(roomNumber)
  //window.location.href = `/joinchat?room=${roomNumber}`;
  navigate(`/joinchat?room=${roomNumber}`)
}

const displayFirstChat = () => {
    axios.get(`${process.env.REACT_APP_BACKEND}/displayFirstChat`).then((response) => {
      //console.log('Full axios response first chat', response) 
        if (response.data.chat.length>0){
            //console.log('Receiving data first chat', response.data.chat)
            const roomNumberFirstChat = response.data.chat
            //console.log('HERE IS THE ROOM', roomNumberFirstChat)
            linkToFirstChat(roomNumberFirstChat)
        } else {
          generateRandomString()
          //console.log('No chat received')
        }
    }) 
    .catch((error) => {
        console.error('Error', error.message)
    })
} 

const linkToFirstChat =(roomNumber) => {
  const link = roomNumber ? `/joinchat?room=${roomNumber}` : '/joinchat';
  window.location.href = link;
}

const checkIfChatExists = async (initialRoomNumber) => {
  //console.log('checkIfChatExists', initialRoomNumber)
  const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExistsRandomRoom?roomNumber=${initialRoomNumber}`;
  //console.log('url is', url)
  try {
      const response = await axios.get(url);
      //console.log('Full axios response chats', response);
      if (response.data.specialMessage) {
          //console.log('Receiving data chat', response.data.specialMessage);
          if (response.data.specialMessage === 'chatExists') {
              //console.log('exists', response.data.specialMessage);
              return true;
          } else {
              //console.log('chat does not exist', response.data.specialMessage);
              return false;
          }
      } else {
          return false; // Handle the case where specialMessage is not present
      }
  } catch (error) {
      console.error('Error', error.message);
      return false; // Return false or handle as needed in case of an error
  }
} 

const [randomRoom, setRandomRoom] = useState('')

const generateRandomString = async () => {

      //console.log('generate')

      let newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);

      let numberExists = await checkIfChatExists(newRandomRoom);
      //console.log('numberExists 1', numberExists)
      while (numberExists) {
        //console.log('number exists, generating new random room...');
        newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
        //console.log('new random room:', newRandomRoom);
        numberExists = await checkIfChatExists(newRandomRoom);    
        //console.log('numberExists new', numberExists)
      }

      //console.log('final random room:', newRandomRoom);
      setRandomRoom(newRandomRoom);
      //console.log('random room', newRandomRoom);
      linkToChat(newRandomRoom)

};

    document.addEventListener('click', (event) => {
      const block = document.getElementById('inviteBox');
      const blockResponsive = document.getElementById('inviteBoxResponsive');
      const open = document.getElementById('svgButton');
      const openResponsive = document.getElementById('svgButtonResponsive');
      
      if (open.contains(event.target) || openResponsive.contains(event.target)) {
          if (open.contains(event.target)) {
              //console.log('first');
          } else {
              //console.log('first Responsive');
          }
          setNotificationBox(1);
      } else if (!block.contains(event.target) && !blockResponsive.contains(event.target)) {
          //console.log('second');
          setNotificationBox(0);
      }
  })

  const [showMenu, setShowMenu] = useState(0)

  document.addEventListener('click', (event) => {
    const block = document.getElementById(`navbarResponsiveColumnId`);
 
    const open = document.getElementById(`menuResponsiveId`);
    //console.log(block)
    if(open.contains(event.target)) {
        setShowMenu(1)
    } else if(!block.contains(event.target)) {
        setShowMenu(0)
    }
})
 
    return ( 
      <>
      {showNavBar === 1 ? <div>
        {/*<button onClick={() => {toggleTheme()}}>{theme}</button>*/}
      <div className="display">
        <nav className="navbar">
            <div className="divForLogo">
              <img src="../images/Assa_logo3.png" alt="#" className="jungle-sign"/>
            </div>
            <a href="/" className="navbar-items"> Main </a>       
            <a href="/courses" className="navbar-items">Courses</a>
            <a href="/account" className="navbar-items">My account</a>
            <div className="chatNotification"><button className="navbar-items" 
                                    onClick={() => {
                                          displayFirstChat();
                                            }}>Chat</button>  
              <button className={friendNameList.length !== 0 ? "svgButton" : "none"} id="svgButton">
                <svg id="svgId" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.10745 2.67414C9.98414 2.24187 10.9649 2 12 2C15.7274 2 18.7491 5.13623 18.7491 9.00497V9.70957C18.7491 10.5552 18.9903 11.3818 19.4422 12.0854L20.5496 13.8095C21.5612 15.3843 20.789 17.5249 19.0296 18.0229C14.4273 19.3257 9.57274 19.3257 4.97036 18.0229C3.21105 17.5249 2.43882 15.3843 3.45036 13.8095L4.5578 12.0854C5.00972 11.3818 5.25087 10.5552 5.25087 9.70957V9.00497C5.25087 7.93058 5.48391 6.91269 5.90039 6.00277" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C12.2445 22 12.4847 21.9827 12.7192 21.9492M16.5 19C16.2329 19.7126 15.781 20.3428 15.1985 20.8393" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>   
              <div className={notificationBox === 0 ? "inviteBoxNone" : "inviteBox"} id="inviteBox">
                        {
                            friendNameList.map((name, index) => {
                                return (
                                  <div>
                                      <div key={name.id} className="invitation">{name} invites you to chat
                                      <div className="joinButtonsDiv">
                                        <button className="joinButton" 
                                        onClick={() => {
                                          //console.log(`postChatNumber(${name}, ${roomNumber[index]}), index: ${index}, list: ${JSON.stringify(roomNumber)}`)
                                          postChatNumber(name, roomNumber[index]);
                                          }}>
                                            Join
                                        </button>
                                        <button className="joinButtonX" 
                                        onClick={() => {
                                          //console.log(`postChatNumber(${name}, ${roomNumber[index]}), index: ${index}, list: ${JSON.stringify(roomNumber)}`)
                                          removeInvitationFunction(name, roomNumber[index]);
                                          }}>
                                            x
                                        </button>
                                        </div>
                                      </div> 
                                  </div>
                                )
                            })
                        }
              </div>
            </div>
            <a href="/settings" type="button" className="navbar-items" id="loginPage">
                Setup
            </a>
        </nav>

        <nav className="navbarResponsive">
          <div className="divForLogo">
              <img src="../images/Assa_logo3.png" alt="#" className="jungle-sign"/>
            </div>
            <div className="menuResponsive" id="menuResponsiveId">
            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
            <path d="M4 6H20M4 12H20M4 18H20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </div>
            <div className={showMenu === 0 ? "showMenuClose" : "navbarResponsiveColumn"} id="navbarResponsiveColumnId">
            <a href="/" className="navbar-itemsResponsive"> Main </a>       
            <a href="/courses" className="navbar-itemsResponsive">Courses</a>
            <a href="/account" className="navbar-itemsResponsive">My account</a>
            <div className="chatNotification"><button className="navbar-itemsResponsive" 
                                    onClick={() => {
                                          displayFirstChat();
                                            }}>Chat</button>  
              <button className={friendNameList.length !== 0 ? "svgButton" : "none"} id="svgButtonResponsive">
                <svg id="svgId" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.10745 2.67414C9.98414 2.24187 10.9649 2 12 2C15.7274 2 18.7491 5.13623 18.7491 9.00497V9.70957C18.7491 10.5552 18.9903 11.3818 19.4422 12.0854L20.5496 13.8095C21.5612 15.3843 20.789 17.5249 19.0296 18.0229C14.4273 19.3257 9.57274 19.3257 4.97036 18.0229C3.21105 17.5249 2.43882 15.3843 3.45036 13.8095L4.5578 12.0854C5.00972 11.3818 5.25087 10.5552 5.25087 9.70957V9.00497C5.25087 7.93058 5.48391 6.91269 5.90039 6.00277" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C12.2445 22 12.4847 21.9827 12.7192 21.9492M16.5 19C16.2329 19.7126 15.781 20.3428 15.1985 20.8393" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>   
              <div className={notificationBox === 0 ? "inviteBoxNone" : "inviteBox"} id="inviteBoxResponsive">
                        {
                            friendNameList.map((name, index) => {
                                return (
                                  <div>
                                      <div key={name.id} className="invitation">{name} invites you to chat
                                      <div className="joinButtonsDiv">
                                        <button className="joinButton" 
                                        onClick={() => {
                                          //console.log(`postChatNumber(${name}, ${roomNumber[index]}), index: ${index}, list: ${JSON.stringify(roomNumber)}`)
                                          postChatNumber(name, roomNumber[index]);
                                          }}>
                                            Join
                                        </button>
                                        <button className="joinButtonX" 
                                        onClick={() => {
                                          //console.log(`postChatNumber(${name}, ${roomNumber[index]}), index: ${index}, list: ${JSON.stringify(roomNumber)}`)
                                          removeInvitationFunction(name, roomNumber[index]);
                                          }}>
                                            x
                                        </button>
                                        </div>
                                      </div> 
                                  </div>
                                )
                            })
                        }
              </div>
            </div>
            <a href="/settings" type="button" className="navbar-itemsResponsive" id="loginPage">
                Setup
            </a>
            </div>
        </nav>
         </div>
         <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
         <div className="alertDiv-content">
             <i className="check">

             <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
             <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
             <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
             </svg>
             </i>

             <div className="messageDiv">
             <span className="textDiv textDiv-1">Success</span>
             <span className="textDiv textDiv-2">{description}</span>
             </div>
         </div>
         <button className="close" onClick={() => {closeAlert()}}>X</button>

         <div className="progressDiv active"></div>
         </div>

         <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>

         <div className="alertDiv-content">
             <i className="checkWrong">

             <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
             <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
             </svg>
             </i>

             <div className="messageDiv">
             <span className="textDiv textDiv-1">Ops</span>
             <span className="textDiv textDiv-2">{description}</span>
             </div>
         </div>
         <button className="close" onClick={() => {closeAlert()}}>X</button>

         <div className="progressDiv active"></div>
     </div>
     </div> :
     <></>}
     </>
    )
}