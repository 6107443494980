import React from "react"

export default function About() {
    return (
        <div className="contactPage" style={{ display: 'none' }}>
        <div className="contactPageDiv">
        <div className="aboutPageColumn">
            <div className="aboutPageInfoParagraph1">
            Assa is an app for learning Chinese language with associations. 
            Every Chinese character consists of small parts that are called radicals. 
            The radicals are mostly not divided parts. 
            Every of them has its own meaning. 
            And the characters combine the radicals not just randomly, usually Chinese people were putting a meaning into combining them. 
            Chinese characters are unique, the modern characters are simplified versions of the traditional characters, but they still consist in parts of the radicals that traditional characters consisted of.
            </div>
            <div className="aboutPageInfoParagraph2">
            In our app we create our own associations for the characters to better memorize them based on the radicals that they consist of.
            We hope this way will help you to remember characters in associations way but not as some random pictures which exist in infinite amount because there around 80 000 characters in Chinese language.
            You will notice the connection of characters based on theirs meaning in a while, that can help you even analyze the connection that Chinese people were putting into it. 
            So, you don't know how to remember characters? Then try out Assa
            </div>
            </div>
        <div className="contactInfoDiv">
        <div>If you want to contact us, please email us at assa.chinese@gmail.com </div>
        <div>We will be glad to help you</div>
        <div>Best regards,</div>
        <div>Assa</div>
        </div>

        <div className="aboutPageInfoParagraphChatInfo">
        The chat is created specifically for educational purposes only
        </div>
        </div>
    </div>
    )
}