import React, { useEffect, useState, useRef, useContext }  from "react"
import axios from 'axios'
import { useNavigate, useLocation } from "react-router-dom"
import { CodeContext } from "../context/Context";

export default function Footer() {

    const navigate = useNavigate();

    const {code, setCode} = useContext(CodeContext)

    const displayFirstChat = () => {
        axios.get(`${process.env.REACT_APP_BACKEND}/displayFirstChat`).then((response) => {
          //console.log('Full axios response first chat', response) 
            if (response.data.chat.length>0){
                //console.log('Receiving data first chat', response.data.chat)
                const roomNumberFirstChat = response.data.chat
                //console.log('HERE IS THE ROOM', roomNumberFirstChat)
                linkToFirstChat(roomNumberFirstChat)
            } else {
              generateRandomString()
              //console.log('No chat received')
            }
        }) 
        .catch((error) => {
            console.error('Error', error.message)
        })
    } 

    const checkIfChatExists = async (initialRoomNumber) => {
      //console.log('checkIfChatExists', initialRoomNumber)
      const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExistsRandomRoom?roomNumber=${initialRoomNumber}`;
      //console.log('url is', url)
      axios.get(url).then((response) => {
        //console.log('Full axios response chats', response) 
          if (response.data.specialMessage && response.data.specialMessage){
                  //console.log('Receiving data chat', response.data.chat)
                  if (response.data.specialMessage == 'chatExists'){
                      //console.log('exists', response.data.specialMessage)
                      return true
                  }else{
                      //console.log(response.data.specialMessage)
                      return false
                  }
          }
      }) 
      .catch((error) => {
        console.error('Error', error.message)
      })
  } 

    const [randomRoom, setRandomRoom] = useState('')

    const generateRandomString = async () => {

          let newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
    
          let numberExists = await checkIfChatExists(newRandomRoom);
          //console.log('numberExists 1', numberExists)
          while (numberExists) {
            //console.log('number exists, generating new random room...');
            newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
            //console.log('new random room:', newRandomRoom);
            numberExists = await checkIfChatExists(newRandomRoom);    
            //console.log('numberExists new', numberExists)
          }
    
          //console.log('final random room:', newRandomRoom);
          setRandomRoom(newRandomRoom);
          //console.log('random room', newRandomRoom);
          //setCode(newRandomRoom)
          linkToChat(newRandomRoom)
      };

    const linkToFirstChat =(roomNumber) => {
        const link = roomNumber ? `/joinchat?room=${roomNumber}` : '/joinchat';
        window.location.href = link;
      }

      const linkToChat =(roomNumber) => {

        //console.log('Here is the room number', roomNumber)
        setCode(roomNumber)
        //window.location.href = `/joinchat?room=${roomNumber}`;
        navigate(`/joinchat?room=${roomNumber}`)
      }

    return (
        <div className="footerDiv">
            <div className="footerDivColumn">
            <a href="/about" className="footerLink1">About</a>
            <a href="/copyrightInfo" className="footerLink3">Copyright</a>
            </div>

            <div className="footerDivColumn">
            <a href="/" className="footerLink1">Main</a>
            <a href="/courses" className="footerLink2">Courses</a>
            <a href="/account" className="footerLink3">MyAccount</a>
            </div>

            <div className="footerDivColumn">
            <div className="footerLink1" onClick={() => {
                                          displayFirstChat();
                                            }}>Chat</div>
            <a href="/settings" className="footerLink2">Setup</a>
            </div>
        </div>
    )
}