import React, { useEffect, useState, createContext, useContext } from "react"
import io from "socket.io-client"
import axios from 'axios'
import { useNavigate, useLocation } from "react-router-dom"
import { useRef } from "react";

import { CodeContext } from "../context/Context";
import { FriendNameContext } from "../context/ContextFriendName";

const socket = io.connect(`${process.env.REACT_APP_BACKEND.replace('/api', '')}`, {
    path: '/socket.io',
    transports: ['websocket'],
    secure: false,  // Set to true if using HTTPS
});

//const socket = io.connect("${process.env.REACT_APP_BACKEND}")


// todo : rename this to ChatMessage, or Message, or similar...
 const My_component = ({username, messageContent, index, idFromDatabase, keyForRemovingOnline, displayMessagesFromDatabaseFunction, scrollToBottom, room}) => {

    const location = useLocation();

    const [removeMessage, setRemoveMessage] = useState(0)

    const changeMessage =(gotKey) => {
        //console.log('key', gotKey)
        //console.log('changeMessage in online', messageContent)
        //setRemoveMessage(1)
        const messageData = {
            author: username,
            message: messageContent,
            room: room
        };
        socket.emit("delete_message", messageData);
        removeMessageFunction()
    }

    const removeMessageFunction =() => {
        //console.log('idFromDatabase', messageContent.idFromDatabase)
        axios.post(`${process.env.REACT_APP_BACKEND}/removeMessage`, {
            chatId: messageContent.idFromDatabase
        }).then((response) => {
            //alert(response.data.message)
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }
    
    const [removeStyle, setRemoveStyle] = useState(0)
    const [threeDotsStyle, setThreeDotsStyle] = useState(0)

    /*const setStyle =() => {
        setRemoveStyle(1)
        setThreeDotsStyle(1)
    }*/

        useEffect(() => {
            scrollToBottom()
        }, [messageContent]);

        const documentRef = useRef(document); 

        useEffect(() => {
            const handleClick = (event) => {
                const block = document.getElementById(`divId${index}`);
                const open = document.getElementById(`threeDotsId${index}`);
                if (open && open.contains(event.target)) {
                    setRemoveStyle(1)
                    setThreeDotsStyle(1)
                } else if (block && !block.contains(event.target)) {
                    setThreeDotsStyle(0)
                    setRemoveStyle(0)
                }
            };
    
            documentRef.current.addEventListener('click', handleClick);
    
            return () => {
                documentRef.current.removeEventListener('click', handleClick);
            };
        }, []);

        const navigate = useNavigate();

        const linkToTheUserPageFunction = (friend) => {
            //console.log('Here is the friend', friend)
            //window.location.href = `/account?user=${friend}`;
            navigate(`/account?user=${friend}`)
          };

    return (
        <div
        className="message" 
        id={removeMessage === 0 ? (username === messageContent.author ? "you" : "other") : "message2"}
      >
        {messageContent.author === username ? (
            <>
                <button className={threeDotsStyle === 0 ? "threeDotsRemoveButton" : "threeDotsRemoveButtonOnClick"} id={`threeDotsId${index}`}  /*onClick={setStyle}*/>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                </button>
    
                <button className={removeStyle === 0 ? "removeButtonNone" : "removeButton"} id={`removeButtonId${index}`} onClick={() => {changeMessage(keyForRemovingOnline)}}>remove</button>                                                         
           
                <div id={threeDotsStyle === 0 ? "none" : `divId${index}`}>
                    <div className="messageContent" >
                        <p style={{ whiteSpace: "pre-line" }}>{messageContent.message}</p>
                    </div>
                    <div className="messageMeta">
                        <p className="messageTime">{messageContent.time}</p>
                        <p id="author">{messageContent.author}</p>
                    </div>
                </div>
            </>
        ) : (
            <>
            <div id={threeDotsStyle === 0 ? "none" : `divId${index}`}>
                <div className="messageContent" >
                    <p style={{ whiteSpace: "pre-line" }}>{messageContent.message}</p>
                </div>
                <div className="messageMeta">
                    <p className="messageTime">{messageContent.time}</p>
                    <p id="author">{messageContent.author}</p>
                </div>
            </div>

            <button className={threeDotsStyle === 0 ? "threeDotsRemoveButton" : "threeDotsRemoveButtonOnClick"} id={`threeDotsId${index}`}  /*onClick={setStyle}*/>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
            </button>
    
            <button className={removeStyle === 0 ? "removeButtonNone" : "linkToUsersPageButton"} id={`removeButtonId${index}`} onClick={() => {linkToTheUserPageFunction(messageContent.author)}}>Users page</button>
            </>
             )}
        </div>
    )
}

const MessagesFromDatabase = ({username, messageContent, nameFromDatabase, content, formattedTime, index, room, id, keyForRemoving}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [removeMessage, setRemoveMessage] = useState(0)

    const changeMessage =(gotKey) => {
        //console.log('key', gotKey)
        //console.log('changeMessage in database')
        //setRemoveMessage(1)
        const messageData = {
            author: username,
            message: messageContent,
            room: room
        };
        socket.emit("delete_message", messageData);
        removeMessageFunction()
    }

    const removeMessageFunction =() => {
        axios.post(`${process.env.REACT_APP_BACKEND}/removeMessage`, {
            chatId: id
        }).then((response) => {
            //alert(response.data.message)
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }
    
    const [removeStyle, setRemoveStyle] = useState(0)
    const [threeDotsStyle, setThreeDotsStyle] = useState(0)

    /*const setStyle =() => {
        setRemoveStyle(1)
        setThreeDotsStyle(1)
    }*/


        const documentRef = useRef(document); 

        useEffect(() => {
            const handleClick2 = (event) => {
                const block = document.getElementById(`divIdMessageFromDatabase${index}`);
                const open = document.getElementById(`threeDotsIdMessageFromDatabase${index}`);
                if (open && open.contains(event.target)) {
                    setRemoveStyle(1)
                    setThreeDotsStyle(1)
                } else if (block && !block.contains(event.target)) {
                    setThreeDotsStyle(0)
                    setRemoveStyle(0)
                }
            };
    
            documentRef.current.addEventListener('click', handleClick2);
    
            return () => {
                documentRef.current.removeEventListener('click', handleClick2);
            };
        }, []);

    const linkToTheUserPageFunction = (friend) => {
        //console.log('Here is the friend', friend)
        //window.location.href = `/account?user=${friend}`;
        navigate(`/account?user=${friend}`)
      };

    return (
        <div
        className="message" 
        id={removeMessage === 0 ? (username === nameFromDatabase ? "you" : "other") : "message2"}
      >
        {nameFromDatabase === username ? (
            <>
                <button className={threeDotsStyle === 0 ? "threeDotsRemoveButton" : "threeDotsRemoveButtonOnClick"} id={`threeDotsIdMessageFromDatabase${index}`}  /*onClick={setStyle}*/>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                </button>
    
                <button className={removeStyle === 0 ? "removeButtonNone" : "removeButton"} id={`removeButtonIdMessageFromDatabase${index}`} onClick={() => {changeMessage(keyForRemoving)}}>remove</button>                                                         
           
                <div id={threeDotsStyle === 0 ? "none" : `divIdMessageFromDatabase${index}`}>
                    <div className="messageContent" >
                        <p style={{ whiteSpace: "pre-line" }}>{content}</p>
                    </div>
                    <div className="messageMeta">
                        <p className="messageTime">{formattedTime}</p>
                        <p id="author">{nameFromDatabase}</p>
                    </div>
                </div>
            </>
        ) : (
            <>
            <div id={threeDotsStyle === 0 ? "none" : `divIdMessageFromDatabase${index}`}>
                <div className="messageContent" >
                    <p style={{ whiteSpace: "pre-line" }}>{content}</p>
                </div>
                <div className="messageMeta">
                    <p className="messageTime">{formattedTime}</p>
                    <p id="author">{nameFromDatabase}</p>
                </div>
            </div>

            <button className={threeDotsStyle === 0 ? "threeDotsRemoveButton" : "threeDotsRemoveButtonOnClick"} id={`threeDotsIdMessageFromDatabase${index}`}  /*onClick={setStyle}*/>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
                    <div className="blackDotRemoveButton"></div>
            </button>
    
            <button className={removeStyle === 0 ? "removeButtonNone" : "linkToUsersPageButton"} id={`removeButtonIdMessageFromDatabase${index}`} onClick={() => {linkToTheUserPageFunction(nameFromDatabase)}}>Users page</button>
            </>
             )}
        </div>
    )
}

const ChatRoomButton = ({room, chatName, userName, index, currentRoom, linkToChat, displayChat}) => {

    //console.log('ROOM IS ////////', room)
    const navigate = useNavigate();
    const location = useLocation();

    const {code, setCode} = useContext(CodeContext)

    //const [userName, setUserName] = useState('');
    const [userNameIndex, setUserNameIndex] = useState(0);

    /*useEffect(() => {
        //displayChatName(room)
        //console.log('use effect room', room)
        const useEffectRoom = room
        //console.log('use effect current room', useEffectRoom)
        const isPrivateRoom = (useEffectRoomCheck) => {
            //console.log('I WORK IN USE EFFECT CHECKING THIS ROOM', useEffectRoomCheck); 
            //console.log(typeof(useEffectRoomCheck))
            const checkingName = useEffectRoomCheck.endsWith(':private')
            //console.log(checkingName)
            return checkingName;
        };
            const isPrivate = isPrivateRoom(useEffectRoom);
            if (isPrivate) {
                //console.log('NEW ROOM IS', currentRoom)
                setUserNameIndex(1);
                displayFriendName(useEffectRoom);
            } else {
                //console.log('not private');
                setUserNameIndex(0);
                setUserName('');
            }
    }, [room, location.search]);*/

    const [removeStyleChat, setRemoveStyleChat] = useState(0)
    const [threeDotsStyleChat, setThreeDotsStyleChat] = useState(0)
    const [askingBox, setAskingBox] = useState(0)
    const [askingBoxRenameChat, setAskingBoxRenameChat] = useState(0)

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')
    const [nameOfChat, setNameOfChat] = useState('')
    const [roomNameFromDatabase, setRoomNameFromDatabase] = useState('')

    const blockRef = useRef(null);
    const openRef = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            const block = blockRef.current;
            const open = openRef.current;
            //console.log('block is', block);
            //console.log('open is', open);

            if (open && open.contains(event.target)) {
                setRemoveStyleChat(1);
                setThreeDotsStyleChat(1);
            } else if (block && !block.contains(event.target)) {
                setThreeDotsStyleChat(0);
                setRemoveStyleChat(0);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [index]);

    useEffect(() => {
        const handleResize = () => {
            setAskingBox(0); 
            setAskingBoxRenameChat(0)
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const removeChatFunction = () => {
        setAskingBox(1)
      };

      const renameChatFunction = () => {
        setAskingBoxRenameChat(1)
      };

      const renameChatFunctionOff = () => {
        setAskingBoxRenameChat(0)
      };

      const removeChatFunctionOff = () => {
        setAskingBox(0)
      };

      const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setDescription('')
    }

    const displayFirstChat = () => {
        axios.get(`${process.env.REACT_APP_BACKEND}/displayFirstChat`).then((response) => {
            //alert(response.data.message)
          //console.log('Full axios response first chat', response) 
            if (response.data.chat.length>0){
                //console.log('Receiving data first chat', response.data.chat)
                const roomNumberFirstChat = response.data.chat
                //console.log('HERE IS THE ROOM', roomNumberFirstChat)
                displayChat()
                linkToFirstChat(roomNumberFirstChat)
            } else {
              generateRandomString()
              //console.log('No chat received')
            }
        }) 
        .catch((error) => {
            console.error('Error', error.message)
        })
    } 
    
    const linkToFirstChat =(roomNumber) => {
      const link = roomNumber ? `/joinchat?room=${roomNumber}` : '/joinchat';
      //window.location.href = link;
      navigate(link)
    }

    const checkIfChatExists = async (initialRoomNumber) => {
        //console.log('checkIfChatExists', initialRoomNumber)
        const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExistsRandomRoom?roomNumber=${initialRoomNumber}`;
        //console.log('url is', url)
        axios.get(url).then((response) => {
          //console.log('Full axios response chats', response) 
            if (response.data.specialMessage && response.data.specialMessage){
                    //console.log('Receiving data chat', response.data.chat)
                    if (response.data.specialMessage == 'chatExists'){
                        //console.log('exists', response.data.specialMessage)
                        return true
                    }else{
                        //console.log(response.data.specialMessage)
                        return false
                    }
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    
    const [randomRoom, setRandomRoom] = useState('')
    
    const generateRandomString = async () => {

      let newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);

      let numberExists = await checkIfChatExists(newRandomRoom);
      //console.log('numberExists 1', numberExists)
      while (numberExists) {
        //console.log('number exists, generating new random room...');
        newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
        //console.log('new random room:', newRandomRoom);
        numberExists = await checkIfChatExists(newRandomRoom);    
        //console.log('numberExists new', numberExists)
      }

      //console.log('final random room:', newRandomRoom);
      setRandomRoom(newRandomRoom);
      //console.log('random room', newRandomRoom);
      setCode(newRandomRoom)
      linkToChat(newRandomRoom)
    };

      const removeChatRequest =(room) => {
        //console.log('room for removing', room)
        setAskingBox(0)
        axios.post(`${process.env.REACT_APP_BACKEND}/removeChat`, {
            room: room
        }).then((response) => {
            //alert(response.data.message)
            //setAlertState(1);
            //const descr = response.data.message
            //setDescription(descr)
            //setTimeout(() => {
                //setAlertState(0);
                //setDescription('')
              //}, 5000);
              //console.log('removed')
              displayFirstChat()
        }).catch((err) => {
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const renameChatFunctionPost =(room) => {
        //console.log('NAME OF CHAT IS', nameOfChat)    
        if(nameOfChat.length != 0) {
        axios.post(`${process.env.REACT_APP_BACKEND}/renameChat`, {
            nameOfChat: nameOfChat, 
            room: room
        }).then((response) => {
            //alert(response.data.message)
            /*setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
                setTimeout(() => {
                    setAlertState(0);
                    setDescription('')
                }, 5000);*/

            displayChat()
            renameChatFunctionOff()
        }).catch((err) => {
            setAlertStateWrong(1);
                const descrWrong = err.response.data.message
                setDescription(descrWrong)
                setTimeout(() => {
                    setAlertStateWrong(0);
                    setDescription('')
                }, 5000);
            //console.err('Error fetching data', err.message)
        }) 
        } else{
            setAlertStateWrong(1);
                const descrWrong = "The field can't be empty"
                setDescription(descrWrong)
                setTimeout(() => {
                    setAlertStateWrong(0);
                    setDescription('')
                }, 5000);
        }
    }


    /*const displayFriendName = async (currentRoomPage) => {
        console.log('I WORK HERE 1')
        console.log('NUMBER IS', currentRoomPage)
        const url = `${process.env.REACT_APP_BACKEND}/displayFriendNamePrivateMessage?roomNumber=${currentRoomPage}`;
        
        try {
            const response = await axios.get(url);
            console.log('Full axios response friends', response);
    
            if (response.data && response.data.friendNamePrivate) {
                console.log('Receiving data friend', response.data.friendName);
                console.log('NAME IS', response.data.friendName);
                console.log('I RETURN RESULT')
                setUserName(response.data.friendNamePrivate);
            } else {
                console.log('No friend names received or invalid data structure');
            }
        } catch (error) {
            console.error('Error', error.message);
        }
    };*/

    const displayChatName =(room) => {     
        const url = `${process.env.REACT_APP_BACKEND}/displayChatName?roomNumber=${room}`;
            axios.get(url).then((response) => {
                //alert(response.data.username)
                if (response.data.chatName){
                    setRoomNameFromDatabase(response.data.chatName)
                    //console.log('ROOM NAME FROM DATABASE IS', roomNameFromDatabase)
                } 
            }).catch(err => {
                //alert(err.message)
            });           
    }

    const [nameOfChatError, setNameOfChatError] = useState('');

    const validateNameOfChat = (value) => {
        const regex = /^[a-zA-Z0-9\-_()(\s\u0400-\u04FF]+$/;
        if (value.length < 1) {
            setNameOfChatError('Name of chat must contain at least one character');
            return false;
        } else if (!regex.test(value)) {
            setNameOfChatError('Name of chat can only contain letters, numbers, hyphens, underscores and ( ) characters');
            return false;
        } else if (value.length > 15) {
            setNameOfChatError('Maximum 15 characters allowed for name of chat');
            return false;
        } else {
            setNameOfChatError('');
            return true;
        }
    };

    const handleCommonClick = (event) => {
        // Check if the click originated from the 'extra' button
        if (!event.target.closest('.threeDotsRemoveButtonChat') 
            && !event.target.closest('.removeChatButton')
            && !event.target.closest('.askingBox')
        ) {
            //console.log('Navigating to chat room:', room);
            linkToChat(room);
          } else {
            //console.log('Click originated from the extra button, not navigating.');
          }
      };

    return (
        <div>
            <div className="chatBlock"  onClick={handleCommonClick}>
              <div ref={blockRef} className={room === currentRoom  ? "chatButtonCurrent" : "chatButton" } id={`chatButton${index}`}>    
              
                <button className={removeStyleChat === 0 ? "removeButtonNone" : "removeChatButton"} id={`renameChatId${index}`} onClick={() => {renameChatFunction()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.186 2.09c.521.25 1.136.612 1.625 1.101.49.49.852 1.104 1.1 1.625.313.654.11 1.408-.401 1.92l-7.214 7.213c-.31.31-.688.541-1.105.675l-4.222 1.353a.75.75 0 0 1-.943-.944l1.353-4.221a2.75 2.75 0 0 1 .674-1.105l7.214-7.214c.512-.512 1.266-.714 1.92-.402zm.211 2.516a3.608 3.608 0 0 0-.828-.586l-6.994 6.994a1.002 1.002 0 0 0-.178.241L9.9 14.102l2.846-1.496c.09-.047.171-.107.242-.178l6.994-6.994a3.61 3.61 0 0 0-.586-.828zM4.999 5.5A.5.5 0 0 1 5.47 5l5.53.005a1 1 0 0 0 0-2L5.5 3A2.5 2.5 0 0 0 3 5.5v12.577c0 .76.082 1.185.319 1.627.224.419.558.754.977.978.442.236.866.318 1.627.318h12.154c.76 0 1.185-.082 1.627-.318.42-.224.754-.559.978-.978.236-.442.318-.866.318-1.627V13a1 1 0 1 0-2 0v5.077c0 .459-.021.571-.082.684a.364.364 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.57-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684V5.5z" fill="#000000"/>
                </svg>                               
                </button>
                  
                  <div className="chatNumber">
                  {chatName != undefined ? chatName : (userName ? userName : `Chat ${index + 1}`)} 
                  </div>  

                  <button ref={openRef} className={threeDotsStyleChat === 0 ? "threeDotsRemoveButtonChat" : "threeDotsRemoveButtonOnClick"} id={`threeDotsIdChat${index}` }>
                          <div className="blackDotRemoveButton"></div>
                          <div className="blackDotRemoveButton"></div>
                          <div className="blackDotRemoveButton"></div>
                  </button>

                  <button className={removeStyleChat === 0 ? "removeButtonNone" : "removeChatButton"} id={`removeButtonIdChat${index}`} onClick={() => {removeChatFunction()}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                      <path d="M19 9H22M19 14H22M19 19H21M16 6L15.1991 18.0129C15.129 19.065 15.0939 19.5911 14.8667 19.99C14.6666 20.3412 14.3648 20.6235 14.0011 20.7998C13.588 21 13.0607 21 12.0062 21H7.99377C6.93927 21 6.41202 21 5.99889 20.7998C5.63517 20.6235 5.33339 20.3412 5.13332 19.99C4.90607 19.5911 4.871 19.065 4.80086 18.0129L4 6M2 6H18M14 6L13.7294 5.18807C13.4671 4.40125 13.3359 4.00784 13.0927 3.71698C12.8779 3.46013 12.6021 3.26132 12.2905 3.13878C11.9376 3 11.523 3 10.6936 3H9.30643C8.47705 3 8.06236 3 7.70951 3.13878C7.39792 3.26132 7.12208 3.46013 6.90729 3.71698C6.66405 4.00784 6.53292 4.40125 6.27064 5.18807L6 6M12 10V17M8 10L7.99995 16.9998" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>                                          
                  </button>    

              </div>

              <div className={askingBox === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Do you want to leave the {chatName != undefined ? `chat ${chatName}` : (userName ? `chat with ${userName}` : `Chat ${index + 1}`)}?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {removeChatRequest(room)}}>Yes</button>
                    <button className="noAnswer" onClick={() => {removeChatFunctionOff()}}>No</button>
                </div>
              </div>

              <div className={askingBoxRenameChat === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRename">Rename the {chatName != undefined ? `chat ${chatName}` : (userName ? `chat ${userName}` : `Chat ${index + 1}`)} </p>
                <div className="divInputAndErrorChatName">
                <input className="renameChatInput" onChange={(e) => {
                            setNameOfChat(e.target.value)
                            validateNameOfChat(e.target.value);
                    }}>
                </input>
                {nameOfChatError && <span className="errorNameOfChat">{nameOfChatError}</span>}
                </div>
                <div className="answerRemove">
                    <button type="submit" className="yesAnswerRenameChat" onClick={() => {renameChatFunctionPost(room)}} disabled={nameOfChatError}>Save</button>
                    <button className="noAnswerRenameChat" onClick={() => {renameChatFunctionOff()}}>Cancel</button>
                </div>
              </div>
                
            </div> 

            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="check">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Success</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
                </div>

                <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Ops</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
            </div>
        </div>
      )
}

export default function Chat({socket, username, codeChat, infoToChat}) {

    const location = useLocation();
    const navigate = useNavigate();

    
    const queryParams = new URLSearchParams(location.search);
    const initialRoomNumber = queryParams.get('room');
   
    const room = initialRoomNumber
  
    //console.log('THE ROOM HERE IS', room)

    const {code, setCode} = useContext(CodeContext)
    const {friendNameFromContext, setFriendNameFromContext} = useContext(FriendNameContext)

    //console.log(`Chat Component (${socket}, ${username}, ${room})`)

    const [currentMessage, setCurrentMessage] = useState("")
    const [messageList, setMessageList] = useState([])
    const [currentRoom, setCurrentRoom] = useState('');
    const [roomNumber, setRoomNumber] = useState([])
    //console.log('Here username', username)

    const sendMessage = async () => {
        if (currentMessage !== "") {
            //console log here
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedTime = hours + ":" + formattedMinutes;
            //console.log('FORMATED TIME IS', formattedTime);

            const yearTimestamp = currentTime.getFullYear();
            const monthTimestamp = (currentTime.getMonth() + 1).toString().padStart(2, '0');
            const dayTimestamp = currentTime.getDate().toString().padStart(2, '0');
            const hoursTimestamp = currentTime.getHours().toString().padStart(2, '0');
            const minutesTimestamp = currentTime.getMinutes().toString().padStart(2, '0');
            const secondsTimestamp = currentTime.getSeconds().toString().padStart(2, '0');

            const timestampData = `${yearTimestamp}-${monthTimestamp}-${dayTimestamp} ${hoursTimestamp}:${minutesTimestamp}:${secondsTimestamp}`;
            //console.log('TIMESTAMP IS', timestampData)

            const expirationTime = currentTime
            expirationTime.setHours(expirationTime.getHours() + 240);
            const formattedExpirationTime = expirationTime.toISOString().slice(0, 19).replace('T', ' ');
            //console.log('Expiration time is', formattedExpirationTime)
            const messageData = {
                room: room,
                author: username,
                message: currentMessage,
                time: formattedTime, 
                current_time: timestampData,
                timestamp: formattedExpirationTime
            }
            //console.log("MESSAGE", messageData)

            await socket.emit("send_message", messageData)

            setMessageList((list) => [...list, messageData])
            setCurrentMessage("")
            const idFromDatabase = await saveMessageFunction(messageData)
            messageData.idFromDatabase = idFromDatabase;
            scrollToBottom();
        } 
    }

    const [idFromDatabaseState, setIdFromDatabaseState] = useState(0)

    const saveMessageFunction = async (messageData) => {
        try {
            // Sending the POST request and awaiting the response
            //console.log('message data for saving', messageData)
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/saveMessage`, {
                room: messageData.room,
                author: messageData.author,
                message: messageData.message,
                time: messageData.current_time,
                timestamp: messageData.timestamp
            });
    
            // Handling the response
            return response.data.idFromDatabase;
        } catch (err) {
            // Handling the error
            console.log(JSON.stringify(err.response.data.message));
        }
    }

    const [messageListFromDatabase, setMessageListFromDatabase] = useState([])

    const displayMessagesFromDatabaseFunction = () => {
        //console.log('ROOM SENDING TO GET IS', room)
        const url = `${process.env.REACT_APP_BACKEND}/displayMessagesFromDatabase?chat=${room}`;
        axios.get(url).then((response) => {
          //console.log('Full axios response chats', response) 
            if (response.data.messagesData && response.data.messagesData){
                //console.log('RECEIVING DATA CHAT!!!!!!!!!!!!!!!!', response.data.messagesData)
                setMessageListFromDatabase(response.data.messagesData)
                scrollToBottom()
            } else {
                alert('No messages received or invalid data structure')
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    useEffect(() => {
        displayMessagesFromDatabaseFunction()
    }, [location.search])

    useEffect(() => {

        setMessageList([])

        const handleMessage = (data) => {
            //console.log("receive_message", JSON.stringify(data), messageList)
            setMessageList((list) => [...list, data])
        }
        
        /*socket.on("receive_message", (data) => {
            console.log("receive_message", JSON.stringify(data), messageList)
            setMessageList((list) => [...list, data])
        })*/

        socket.on("receive_message", handleMessage)

        return () => {
            socket.off("receive_message", handleMessage)
        }

    }, [location.search])


    useEffect(() => {

        const handleDelete = (data) => {

            //console.log('time data 1', data.message.current_time)
            //console.log('data', data.message)
            //console.log('time', data.message.current_time)
            //console.log('time', data.message.time_of_message)

            const list1 = [...messageListFromDatabase]
            //console.log('messages from database /////', list1)
           
            const list1_fix = list1.filter(message => !(message.time_of_message === data.message.time_of_message && message.content === data.message.content))
            //console.log('LIST1_FIX', list1_fix)
            
            const list2 = [...messageList]
            //console.log('messages online /////', list2)

            const list2_fix = list2.filter(message => !(message.current_time === data.message.current_time && message.message === data.message.message))
            //console.log('LIST2_FIX', list2_fix)


            function containsCurrentTimeKey(list1) {
                return list1.some(item => item.hasOwnProperty('current_time'));
            }
            const result = containsCurrentTimeKey(list1);

            if(result === false && data.message.current_time != undefined){
                //console.log('false here')
                //console.log('inside', list1)
                //console.log('inside', data.message.current_time)
                //console.log('inside', data.message.message)
                const list1_fix = list1.filter(message => !(message.time_of_message === data.message.current_time && message.content === data.message.message))
                //console.log('list1_fix FILTER INSIDE', list1_fix)
    
                setMessageListFromDatabase(list1_fix)
            } else{
                setMessageListFromDatabase(list1_fix)
            }

            function containsCurrentTimeKey2(list2) {
                return list2.some(item => item.hasOwnProperty('time_of_message'));
            }
            const result2 = containsCurrentTimeKey2(list2);

            if(result2 === false && data.message.time_of_message != undefined){
                //console.log('false here 2')
                const list2_fix = list2.filter(message => !(message.current_time === data.message.time_of_message && message.message === data.message.content))
                //console.log('list2_fix FILTER INSIDE', list2_fix)
    
                setMessageList(list2_fix)
            } else{
                setMessageList(list2_fix)
            }

        }

        socket.on("message_deleted", handleDelete)

        return () => {
            socket.off("message_deleted", handleDelete)
        }

    }, [location.search, messageListFromDatabase, messageList])

    const [addFriendX, setAddFriendX] = useState(0)
    const [addFriendIndexType, setAddFriendIndexType] = useState(0)
    const [friendNameRequest, setFriendNameRequest] = useState('')
    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [alertStateWrongBlockedUser, setAlertStateWrongBlockedUser] = useState(0)
    const [description, setDescription] = useState('')
    const [openList, setOpenList] = useState(0)
    const [friendNamePrivate, setFriendNamePrivate] = useState(0)

    axios.defaults.withCredentials = true

    const sendInvitation =() => {
        //console.log('I work in send Invitation chat')
        axios.post(`${process.env.REACT_APP_BACKEND}/sendInvitation`, {
            name: friendNameRequest, 
            number: room
        }).then((response) => {
            //console.log(response)
            //console.log('I come back')
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            //console.log(err.message)
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }

    const sendInvitationRandomSearch =() => {
        axios.post(`${process.env.REACT_APP_BACKEND}/sendInvitationRandomSearch`, {
            name: friendNameRequest, 
            number: room
        }).then((response) => {
            //console.log(response)
            //alert(response.data.message)
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            //console.log(err.message)
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
            //alert(JSON.stringify(err.response.data.message))
        })
        
    }

    const addFriendIndex =() => {       
        setAddFriendX(1)
        setAddFriendIndexType(1)
    }

    
    const [showInfoState, setShowInfoState] = useState(0)
    const [showInfoStateResponsive, setShowInfoStateResponsive] = useState(0)


    useEffect(() => {
        const handleClick5 = (event) => {
            const block = document.getElementById('information');
            const open = document.getElementById('svgInfo');
            if (open && open.contains(event.target)) {
                setShowInfoState(1)
            } else if (block && !block.contains(event.target)) {
                setShowInfoState(0)
            }
        };

        documentRef.current.addEventListener('click', handleClick5);

        return () => {
            documentRef.current.removeEventListener('click', handleClick5);
        };
    }, []);

    const documentRef = useRef(document); 
        
    useEffect(() => {
        const handleClick4 = (event) => {
            const block = document.getElementById('informationResponsive');
            const open = document.getElementById('svgInfoResponsive');
            if (open && open.contains(event.target)) {
                setShowInfoStateResponsive(1)
            } else if (block && !block.contains(event.target)) {
                setShowInfoStateResponsive(0)
            }
        };

        documentRef.current.addEventListener('click', handleClick4);

        return () => {
            documentRef.current.removeEventListener('click', handleClick4);
        };
    }, []);

    const closeAddFriend =() => {
        setAddFriendX(0)
        setAddFriendIndexType(0)
    }

    const openListFunction =() => {
        setOpenList(1)
    }

    const closeListFunction =() => {
        setOpenList(0)
    }

    const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setAlertStateWrongBlockedUser(0)
        setDescription('')
    }

    useEffect(() => {
        //console.log('hi')
        displayChat()
        /*setTimeout(() => {
            displayChat()
        }, 5000)*/
      }, [currentRoom, location.search, infoToChat]);

      
  useEffect(() => {
    const getRoomFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const roomParam = searchParams.get('room');
      try {
        if (roomParam) {
          setCurrentRoom((prevRoom) => {
            //console.log('Room set:', roomParam);
            const isPrivate = isPrivateRoom(roomParam)
            if(isPrivate){
                setFriendNamePrivate(1)
            }else{
                setFriendNamePrivate(0)
            }
            displayFriendInChat(roomParam)
            return roomParam;
          });
        } else {
          console.error('Invalid room value:', roomParam);
        }
      } catch (error) {
        console.error('Error setting room value:', error);
      }
    };

    // Call the function whenever the route changes
    getRoomFromURL();
  }, [location.search]);

  const isPrivateRoom = (roomName) => {
    // Check if the last characters of the string are ':private'
    return roomName.endsWith(':private');
  }
    
  useEffect(() => {
    //console.log('INFO TO CHAT', infoToChat)
   if(infoToChat === 1){
    displayChat()
   }

}, [infoToChat, location.search])

    const displayChat = () => {
        //console.log('I AM GOING TO FIND CHATS')
        axios.get(`${process.env.REACT_APP_BACKEND}/displayChat`).then((response) => {
          //console.log('Full axios response chats', response) 
            if (response.data.chat && response.data.chat){
              //console.log('THE CHATS ARE', response.data.chat)
                setRoomNumber(response.data.chat)
                //console.log(response.data.chat)
                //console.log(response.data.chat[0].chat)
                //alert(JSON.stringify(response.data.chat))
            } else {
              console.log('No chat received or invalid data structure')
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    const [privateChatLink, setPrivateChatLink] = useState('')

    const checkIfPrivateChatExists = (friend) => {
        //console.log('checkIfPrivateChatExists5')
        //console.log('FRIENDNAME IS', friend)
        const url = `${process.env.REACT_APP_BACKEND}/checkIfPrivateChatExists?friendName=${friend}`;
        //console.log('url is', url)
        axios.get(url).then((response) => {
          //console.log('Full axios response chats', response) 
            if (response.data.chat && response.data.chat){
                    //console.log('Receiving data chat', response.data.chat)
                    const chatLink = (response.data.chat)
                    const specialMessage = (response.data.specialMessage)
                    generateRandomStringPrivateChat(friend, chatLink, specialMessage)  
            } else {
              console.log('No chat received or invalid data structure')
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    const [dataToSend, setDataToSend] = useState("Hello from Child");

    const linkToChat =(roomNumber) => {

        //console.log('Here is the room number', roomNumber)
        //window.location.href = `/joinchat?room=${roomNumber}`;
        navigate(`/joinchat?room=${roomNumber}`)
      }

      const linkToPrivateChat =(privateChat) => {

        //console.log('Here is the room number', privateChat)
        //window.location.href = `/joinchat?room=${privateChat}`;
        navigate(`/joinchat?room=${privateChat}`)
      }

      const linkToNewPrivateChat =(friendName, privateChat) => {

        //console.log('Here is the room number link to new private chat', privateChat)
        //console.log('FRIEND NAME IS DATA TO SEND', friendName)
        setFriendNameFromContext(friendName)
        //console.log('this friend name chat', friendNameFromContext)
        setCode(privateChat)
        //sendDataToParent(friendName)
        //window.location.href = `/joinchat?room=${privateChat}&friendName=${friendName}`;
        navigate(`/joinchat?room=${privateChat}&friendName=${friendName}`)
      }

      const linkToOnePersonExistingChat =(friendName, privateChat) => {
        //window.location.href = `/joinchat?room=${privateChat}&friendName=${friendName}`;
        //console.log('here', friendName)
        //console.log('here2', privateChat)
        setCode(privateChat)
        navigate(`/joinchat?room=${privateChat}&friendName=${friendName}`)
      }

      /*const sendDataToParent = (friendName) => {
        // Call the callback function passed from the parent
        onDataReceived(friendName);
      };*/

      const [randomRoom, setRandomRoom] = useState('')

      const checkIfPrivateChatExistsFunction = (friend) => {
        //console.log('checkIfPrivateChatExistsFunction1')
        checkIfUserBlockedFunction(friend)
      };

      const linkToTheUserPageFunction = (friend) => {
        //console.log('Here is the friend', friend)
        //window.location.href = `/account?user=${friend}`;
        navigate(`/account?user=${friend}`)
      };

      const generateRandomStringPrivateChat = async (friendName, chatLink, specialMessage) => {
        //console.log('CHAT LINK IS', chatLink)
        //console.log('friendName IS', friendName)
        if(chatLink != 'noChat'){
            //console.log('no Chat')
            //console.log('special meaage', specialMessage)
            if(specialMessage && specialMessage == 'one user in chat'){
                //console.log('one user in chat')
                linkToOnePersonExistingChat(friendName, chatLink)
            } else{
                //console.log('THIS IS A LINK!', chatLink)
                linkToPrivateChat(chatLink)
            }
            }
            else{
                //console.log('CHAT LINK IS', chatLink)

                const newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36) + ':private';

                let numberExists = await checkIfChatExists(newRandomRoom);
                //console.log('numberExists 1', numberExists)
                while (numberExists) {
                    //console.log('number exists, generating new random room...');
                    newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
                    //console.log('new random room:', newRandomRoom);
                    numberExists = await checkIfChatExists(newRandomRoom);    
                    //console.log('numberExists new', numberExists)
                }

                //console.log('final random room:', newRandomRoom);
                setRandomRoom(newRandomRoom);
                //setCode(newRandomRoom)
                //console.log('random room', newRandomRoom);
                linkToNewPrivateChat(friendName, newRandomRoom)
            }
        };

        const checkIfChatExists = async (initialRoomNumber) => {
            //console.log('checkIfChatExists', initialRoomNumber)
            const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExistsRandomRoom?roomNumber=${initialRoomNumber}`;
            //console.log('url is', url)
            try {
                const response = await axios.get(url);
                //console.log('Full axios response chats', response);
                if (response.data.specialMessage) {
                    //console.log('Receiving data chat', response.data.specialMessage);
                    if (response.data.specialMessage === 'chatExists') {
                        //console.log('exists', response.data.specialMessage);
                        return true;
                    } else {
                        //console.log('chat does not exist', response.data.specialMessage);
                        return false;
                    }
                } else {
                    return false; // Handle the case where specialMessage is not present
                }
            } catch (error) {
                console.error('Error', error.message);
                return false; // Return false or handle as needed in case of an error
            }
        } 

      const generateRandomString = async () => {
        //console.log('generate')

        let newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);

        let numberExists = await checkIfChatExists(newRandomRoom);
        //console.log('numberExists 1', numberExists)
        while (numberExists) {
          //console.log('number exists, generating new random room...');
          newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
          //console.log('new random room:', newRandomRoom);
          numberExists = await checkIfChatExists(newRandomRoom);    
          //console.log('numberExists new', numberExists)
        }

        //console.log('final random room:', newRandomRoom);
        setRandomRoom(newRandomRoom);
        //console.log('random room', newRandomRoom);
        setCode(newRandomRoom)
        linkToChat(newRandomRoom)
      };

      const [friendNameList, setFriendNameList] = useState([])
      const [isLoading, setIsLoading] = useState(0)

      useEffect(() => {

        const handleDelete = (data) => {

            //console.log('data', data)

            setFriendNameList((prevList) => [...prevList, data]);
        }

        socket.on("display_user", handleDelete)

        return () => {
            socket.off("display_user", handleDelete)
        }

    }, [location.search, socket])

      const displayFriendInChat = (room) => {
        const currentRoomPage = room 
        const url = `${process.env.REACT_APP_BACKEND}/displayFriendInChat?roomNumber=${currentRoomPage}`;
        axios.get(url).then((response) => {
          //console.log('Full axios response friends', response) 
            if (response.data.friendNames && response.data.friendNames){
              //console.log('Receiving data friends', response.data.friendNames)
                setIsLoading(1)
                setFriendNameList(response.data.friendNames)
            } else {
              console.log('No friend names received or invalid data structure')
              setIsLoading(1)
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    const scrollToBottom = () => {
        const chatBody = document.querySelector(".chatBody");

        chatBody.scrollTop = chatBody.scrollHeight;
    };

    const [messageWritingError, setMessageWritingError] = useState('');

    const validateMessage = (value) => {
        
        
    const chineseRegex = /^[\u4E00-\u9FFF\u3002\uff0c\uff1b\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\uff01\uff1a\s@#￥……%&*—+|{}《》?><":£$^&_~/‘’0-9\-=\(\)!\.,\\'\[\];]+$/;
        if (value.length > 400) {
            setMessageWritingError('Maximum 400 characters are allowed for message');
            return false;
        } else if (!chineseRegex.test(value)) {
            setMessageWritingError('You can use only Chinese language to type');
            return false;
        } else {
            setMessageWritingError('');
            return true;
        }
    };

    const checkIfUserBlockedFunction = (friend) => {
        //console.log('FRIENDNAME IS', friend)
        const url = `${process.env.REACT_APP_BACKEND}/isBlockedUser?friendName=${friend}`;
        axios.get(url).then((response) => {
            if (response.data.message === 'Blocked') {
                setAlertStateWrongBlockedUser(1);
                const descrWrong = 'The user disabled private chat'
                setDescription(descrWrong)
            } else if (response.data.message === 'Blocked yourself') {
                setAlertStateWrongBlockedUser(1);
                const descrWrong = "You can't write to blocked user"
                setDescription(descrWrong)
            }else{
                //console.log('Not blocked 4')
                checkIfPrivateChatExists(friend)
            }          
        }) 
        .catch((error) => {
        })
    } 

    function pasteIntoInput(inputElement, text) {
        const startPos = inputElement.selectionStart;
        const endPos = inputElement.selectionEnd;
    
        inputElement.value = inputElement.value.substring(0, startPos) + text + inputElement.value.substring(endPos, inputElement.value.length);
    
        inputElement.selectionStart = startPos + text.length;
        inputElement.selectionEnd = startPos + text.length;
    }

      /*const [notificationBox, setNotificationBox] = useState(0)

      document.addEventListener('click', (event) => {
        const block = document.getElementById('chatUsersBox');
        const open = document.getElementById('usersListSignId');
        //console.log(block)
        if(open.contains(event.target)) {
            setNotificationBox(1)
        } else if(!block.contains(event.target)) {
            setNotificationBox(0)
        }
    })*/

    return (
        <div className="chat">                                         
            <div className={friendNamePrivate ? "notDisplayed" : (openList === 0 ? "friendsInvitingBoxNotDisplayed" : "friendsInvitingBox")}>
            <button className={ openList === 0 ? "notDisplayed" : "turnXChatFriendList" } onClick={() => {closeListFunction()}}>X</button> 
            <div className={openList === 0 ? "chatUsersNotDisplayed" : "chatUsers"} id="chatUsersBox">
                    <div className="textChats">
                        In chat
                    </div>
                    <div>
                {
                    friendNameList.length > 0 ? (
                            friendNameList.map((friend, index) => {
                                return (
                                  <div key={index}>
                                      <div>
                                        <div className="chatButtonUsersList">
                                            <div onClick={() => {linkToTheUserPageFunction(friend)}} className="friendInChatDiv">
                                            {friend} 
                                            </div>
                                            <button className="svgButtonMessage" onClick={() => {checkIfPrivateChatExistsFunction(friend)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                                <path d="M10 9H17M10 13H17M7 9H7.01M7 13H7.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            </button>
                                          </div>
                                      </div> 
                                  </div>
                                )
                            }) ) : (
                                <div className="emptyList2">No users yet</div>
                              )
                        }
                </div> 
                </div> 
                <button className="usersListSign" id="usersListSignId" onClick={() => {openListFunction()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="40px" height="40px" viewBox="0 0 32 32">
                    <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"/>
                    </svg>
                </button>  
            </div>

            <div className={friendNamePrivate ? "friendsInvitingBoxNotDisplayed2" : "notDisplayed"}>
            <div className="chatUserPrivate" id="chatUsersBox">
                    <div className="textChats">
                        Private
                    </div>
                    <div>
                {
                    friendNameList.length > 0 ? (
                            friendNameList.map((friend, index) => {
                                return (
                                  <div key={index}>
                                      <div>
                                        <button onClick={() => {linkToTheUserPageFunction(friend)}} className="chatButtonUsersList">
                                            <div className="friendInChatDiv">
                                            {friend} 
                                            </div>
                                          </button>
                                      </div> 
                                  </div>
                                )
                            }) ) : (
                                isLoading === 1 ?
                                <div className="emptyList">User didn't join yet</div>:
                                <button className="chatButtonUsersList">...</button>
                              )
                        }
                </div> 
                </div> 

            </div>
            <div className="chatBox">
                    
                    <div className="chatBody">
                        <div className="message-container">
                        {messageListFromDatabase.map((messageContent, index) => {                          
                                return <MessagesFromDatabase 
                                username={username}
                                messageContent={messageContent} 
                                nameFromDatabase={messageContent.sender_name} 
                                content={messageContent.content}
                                formattedTime={messageContent.formatted_time} 
                                index={index}
                                room={messageContent.room_name}
                                id={messageContent.id}
                                key={index}
                                keyForRemoving={index}/>
                        })}
                        {messageList.map((messageContent, index) => {                          
                                return <My_component username={username} messageContent={messageContent} index={index} key={index} keyForRemovingOnline={index} displayMessagesFromDatabaseFunction={displayMessagesFromDatabaseFunction} scrollToBottom={scrollToBottom} room={room}/>
                        })}
                        </div>
                    </div>

                    <div className="sendMessageBox">
                        <textarea type="text" 
                        value={currentMessage}
                        className="messageBox" 
                        placeholder="你好。。。" 
                        onChange={(event) =>{
                            setCurrentMessage(event.target.value)
                            validateMessage(event.target.value);
                            }}
                            
                            onKeyDown={(event) => {
                                if (!messageWritingError && event.key === "Enter") {
                                    if (!event.shiftKey) { // Check if Shift key is not pressed
                                        event.preventDefault();
                                        sendMessage();
                                    }
                                }
                            }}
                            onKeyUp={(event) => {
                                if (event.keyCode == 13 && event.shiftKey) {
                                    if (event.type == "keypress") {
                                        pasteIntoInput(this, "\n");
                                    }
                                    event.preventDefault();
                                }
                            }}
                            >
                        </textarea>
                        {messageWritingError && <span className="errorMessageWriting">{messageWritingError}</span>}
                        <button className="sendmessageButton" onClick={sendMessage} disabled={messageWritingError}>&#9658;</button>
                    </div>
                 
            </div>

            
        <div className="columnChats">    
        <div className={friendNamePrivate ? "notDisplayed" : "friendsInviting"}>
                            
            
            
                <button className="inviteFriendText" onClick={() => {addFriendIndex()}}>+</button> 

                <div className={ addFriendIndexType === 0 ? "svgInfo" : "notDisplayed" } id="svgInfo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 17V11" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#1C274C"/>
                    <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                </div>   

                <div className={ showInfoState === 0 ? "notDisplayed" : "informationAboutChat" }  id="information">Note: the chat messages will be automatically removed in 10 days</div>        
                
                <div className={ addFriendIndexType === 0 ? "commonDivNotDisplayed" : "commonDiv" }>
                <div className="column">
                <form className="addFriend">
                <div>
                    <input type="text" className="buttonFormInvite" placeholder="username"
                        onChange={(e) => {
                            setFriendNameRequest(e.target.value)
                    }}/>
                </div>
                

                <button type="button" className="buttonFormSubmit2Invite" onClick={() => {sendInvitation()}}>Add</button>
                <button type="button" className="buttonFormRandomSearch" onClick={() => {sendInvitationRandomSearch()}}>Random search</button>
                </form>

                
                </div>

                <button className="turnXInviteFriend" onClick={() => {closeAddFriend()}}>X</button>  
          
                </div>
            
            </div>

            <div className="chats">
                    <div className="textChats">
                    <button className="plus">
                    </button>
                        Your chats 
                    <button className="plus" onClick={() => {generateRandomString()}}>
                        +
                    </button>
                    </div>
                    <div>
                {
                            roomNumber.map((room, index) => {
                                return <ChatRoomButton room={room.chat} chatName={room.chatName} userName={room.friendName} index={index} currentRoom={currentRoom} linkToChat={linkToChat} displayChat={displayChat} key={index} />
                            })
                        }
                </div>
                </div>  
                </div>

                <div className={friendNamePrivate ? "friendsInvitingBoxNotDisplayed2Responsive" : "notDisplayed"}>
            <div className="chatUserPrivate" id="chatUsersBox">
                    <div className="textChats">
                        Private
                    </div>
                    <div>
                {
                    friendNameList.length > 0 ? (
                            friendNameList.map((friend, index) => {
                                return (
                                  <div key={index}>
                                      <div>
                                        <button onClick={() => {linkToTheUserPageFunction(friend)}} className="chatButtonUsersList">
                                            <div className="friendInChatDiv">
                                            {friend} 
                                            </div>
                                          </button>
                                      </div> 
                                  </div>
                                )
                            }) ) : (
                                isLoading === 1 ?
                                <div className="emptyList">User didn't join yet</div>:
                                <div></div>
                              )
                        }
                </div> 
                </div> 

            </div>

            <div className="responsiveDivInChat">
                <div className={friendNamePrivate ? "notDisplayed" : (openList === 0 ? "friendsInvitingBoxNotDisplayedResponsive" : "friendsInvitingBoxResponsive")}>
            <button className={ openList === 0 ? "notDisplayed" : "turnXChatFriendList" } onClick={() => {closeListFunction()}}>X</button> 
            <div className={openList === 0 ? "chatUsersNotDisplayed" : "chatUsers"} id="chatUsersBox">
                    <div className="textChats">
                        In chat
                    </div>
                    <div>
                {
                    friendNameList.length > 0 ? (
                            friendNameList.map((friend, index) => {
                                return (
                                  <div key={index}>
                                      <div>
                                        <div className="chatButtonUsersList">
                                            <div onClick={() => {linkToTheUserPageFunction(friend)}} className="friendInChatDiv">
                                            {friend} 
                                            </div>
                                            <button className="svgButtonMessage" onClick={() => {checkIfPrivateChatExistsFunction(friend)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                                <path d="M10 9H17M10 13H17M7 9H7.01M7 13H7.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            </button>
                                          </div>
                                      </div> 
                                  </div>
                                )
                            }) ) : (
                                <div className="emptyList2">No users yet</div>
                              )
                        }
                </div> 
                </div> 
                <button className="usersListSign" id="usersListSignId" onClick={() => {openListFunction()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="40px" height="40px" viewBox="0 0 32 32">
                    <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"/>
                    </svg>
                </button>  
            </div>

            <div className= {friendNamePrivate ? "columnChatsResponsivePrivate" : "columnChatsResponsive"}>    
        <div className={friendNamePrivate ? "notDisplayed" : "friendsInviting"}>
                            
            
            
                <button className="inviteFriendText" onClick={() => {addFriendIndex()}}>+</button>  

                <div className={ addFriendIndexType === 0 ? "svgInfo" : "notDisplayed" } id="svgInfoResponsive">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 17V11" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                    <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#1C274C"/>
                    <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                </div>   

                <div className={ showInfoStateResponsive === 0 ? "notDisplayed" : "informationAboutChat" }  id="informationResponsive">Note: the chat messages will be automatically removed in 10 days</div>        
                          
                
                <div className={ addFriendIndexType === 0 ? "commonDivNotDisplayed" : "commonDiv" }>
                <div className="column">
                <form className="addFriend">
                <div>
                    <input type="text" className="buttonFormInvite" placeholder="username"
                        onChange={(e) => {
                            setFriendNameRequest(e.target.value)
                    }}/>
                </div>
                

                <button type="button" className="buttonFormSubmit2Invite" onClick={() => {sendInvitation()}}>Add</button>
                <button type="button" className="buttonFormRandomSearch" onClick={() => {sendInvitationRandomSearch()}}>Random search</button>
                </form>

                
                </div>

                <button className="turnXInviteFriend" onClick={() => {closeAddFriend()}}>X</button>  
          
                </div>
            
            </div>

            <div className="chats">
                    <div className="textChats">
                    <button className="plus">
                    </button>
                        Your chats 
                    <button className="plus" onClick={() => {generateRandomString()}}>
                        +
                    </button>
                    </div>
                    <div>
                {
                            roomNumber.map((room, index) => {
                                return <ChatRoomButton room={room.chat} chatName={room.chatName} userName={room.friendName} index={index} currentRoom={currentRoom} linkToChat={linkToChat} displayChat={displayChat} key={index} />
                            })
                        }
                </div>
                </div>  
                </div>
            </div>

                <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="check">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Success</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
                </div>

                <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Ops</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
            </div>

               <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Ops</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
            </div>

            
            <div className={alertStateWrongBlockedUser ? 'alertDiv' : 'notDisplayed' }>
  
            <div className="alertDiv-content">
                <i className="checkWrong">

                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </i>

                <div className="messageDivWrong">
                <span className="textDiv textDiv-2">{description}</span>
                </div>
            </div>
            <button className="close" onClick={() => {closeAlert()}}>X</button>
            </div>
        </div>
    )
}