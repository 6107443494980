import { createContext, useState, useContext } from 'react';
import React from 'react';

export const CodeContext = createContext({code: '--', setCode: () => {}})

export const CodeProvider = ({ children }) => {
    const [code, setCode] = useState('')

    const updateCode = (newCode) => {
        setCode(newCode)
    }

    return (
        <CodeContext.Provider value={{ code, setCode: updateCode}}>
            {children}
        </CodeContext.Provider>
    )
}