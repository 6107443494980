import React from "react"

export default function List() {

    const folders = Array.from({ length: 10 }, (_, index) => ({
        range: `${index * 100 + 1}-${(index + 1) * 100}`,
        link: `/listHSK5-${index + 1}`,
    }));

    return (
        <div className="foldersPage">
            <div className="FolderPageBox">
            <h1 className="h1Style">Words</h1>
            <div className="foldersFlex">
                {folders.map((folder, index) => (
                    <div className="folderBox" key={index}>
                        <svg className="svgRounded" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2">
                            <path d="M16.99 8.95996H7.01001" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 7.28003V8.96002" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.5 8.93994C14.5 13.2399 11.14 16.7199 7 16.7199" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.9999 16.72C15.1999 16.72 13.6 15.76 12.45 14.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <a href={folder.link} className="folderText">{folder.range}</a>
                    </div>
                ))}
            </div>
        </div>
    </div>
    )
}