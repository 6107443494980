import React from "react"

export default function PayedInfo2() {
    const backToTestsLink = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_TWO}/courses`;
      };
    return (
        <div className="notFoundPagePayedCourse">
            <div className="paidCourseText">You already paid for the course</div>
            <button onClick={() => {backToTestsLink()}} className="buttonBackToTestTrial">Back to Tests</button>
        </div>
    )
}