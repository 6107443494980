import React, {useEffect, useRef, useState, useContext} from "react"
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom';
import NotFoundPage from "./NotFoundPage";
import { CodeContext } from "../context/Context";
import { FriendNameContext } from "../context/ContextFriendName";

export default function MyAccount() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialRoomNumber = queryParams.get('user');
    const [usernameFromLink, setUsernameFromLink] = useState(initialRoomNumber || '');
    //console.log('Room number from link', usernameFromLink)

    const [nickName, setNickName] = useState('')
    const [newNickName, setNewNickName] = useState('')
    const [level, setLevel] = useState('')
    const [newLevel, setNewLevel] = useState('')
    const [age, setAge] = useState('')
    const [newAge, setNewAge] = useState('')
    const [info, setInfo] = useState('')
    const [newInfo, setNewInfo] = useState('')

    const [friendName, setFriendName] = useState('')
    const [friendNameInfo, setFriendNameInfo] = useState('')
    const [friendNameList, setFriendNameList] = useState([])
    const [friendNameListRequest, setFriendNameListRequest] = useState([])
    const [friendNameRequest, setFriendNameRequest] = useState('')

    const [alertState, setAlertState] = useState(0)
    const [alertStateWrong, setAlertStateWrong] = useState(0)
    const [description, setDescription] = useState('')

    const [modal, setModal] = useState(0)
    const [addFriendX, setAddFriendX] = useState(0)

    const {code, setCode} = useContext(CodeContext)
    const {friendNameFromContext, setFriendNameFromContext} = useContext(FriendNameContext) 
    
    const navigate = useNavigate();

    const closeAlert =() => {
        setAlertState(0)
        setAlertStateWrong(0)
        setFileError(0)
        setDescription('')
    }

    axios.defaults.withCredentials = true

    const currentDate = new Date();

    // Get the current minutes and seconds components of the date
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

    const Data =(usernameFromLinkData, newInfoFromDatabase) => {
        //console.log(newInfoFromDatabase)
        //console.log('DATA USENAME', usernameFromLinkData)
        const url = `${process.env.REACT_APP_BACKEND}/userdata?usernameFromLink=${usernameFromLinkData}&edit=${newInfoFromDatabase}`;
        axios.get(url).then((response) => {
            //console.log('I work userdata', `${minutes}:${seconds}.${milliseconds}`)
            //setPost(response.data);
            if (response.data.username){
                //console.log('Full response data', response.data)
                //console.log('I work inside userdata', `${minutes}:${seconds}.${milliseconds}`)
                setNickName(response.data.username)
                setLevel(response.data.level)
                setAge(response.data.age)
                setInfo(response.data.info)

                //console.log(response.data.username)
                //console.log(response.data.age)
                //console.log(response.data.info)
            } 
        }).catch(err => {
            console.log('Error is', err)
        });
        
    }

    const DataNickName =(usernameFromLinkData) => {
        
        //console.log('DATA USENAME', usernameFromLinkData)
        const url = `${process.env.REACT_APP_BACKEND}/userdata?usernameFromLink=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
            if (response.data.username){
                setNickName(response.data.username)
            } 
        }).catch(err => {
            
        });
        
    }

    const edit =() => {
        //console.log('I worked first edit', `${minutes}:${seconds}.${milliseconds}`)
        axios.post(`${process.env.REACT_APP_BACKEND}/edit`, {
            name: newNickName,
            level: newLevel, 
            age: newAge,
            info: newInfo
        }).then((response) => {
            getOwnName(usernameFromLink, 'editProfile')
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
              setModal(0)
        }).catch((err) => {
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
            //console.err('Error fetching data', err.message)
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            // console.log('USERNAME FROM LINK IS', usernameFromLink)
            await getOwnName(usernameFromLink);
        };
    
        fetchData();
    }, []);

      /*useEffect(() => {
        console.log('I call a function now', `${minutes}:${seconds}.${milliseconds}`)
        Data()
      }, [data]);*/

      const [ownName, setOwnName] = useState(0)
      const [displayAddingInfo, setDisplayAddingInfo] = useState(0)

      const getOwnName = async (usernameFromLink, newInfoFromDatabase) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/getOwnName`);
            if (response.data.name) {
                //console.log('name is here',response.data.name)
                //setOwnName(response.data.name);
                checkingUserNameFunction(response.data.name, usernameFromLink, newInfoFromDatabase)
            }
        } catch (error) {
            console.error('Error fetching own name:', error);
        }
    };

    const [existingUserPage, setExistingUserPage] = useState(0)

    const check_existing_page =(usernameFromLinkData) => {
        axios.post(`${process.env.REACT_APP_BACKEND}/check_existing_page_my_account`, {
            name: usernameFromLinkData, 
        }).then((response) => {
            //console.log(response)
            //alert(response.data.message)
            if (response.data.message === 'Page exists'){
                checkIfUserBlockedFunction(usernameFromLink)
                checkIfUserBlockedFunctionMessage(usernameFromLink)
                setExistingUserPage(1)
            }else{
                setExistingUserPage(2)
            }
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            const descrWrong = err.response.data.message
            //console.log('here', descrWrong)
        })
        
    }

    const [ownInfo, setOwnInfo] = useState(0)
    const [friendsVisability, setFriendsVisability] = useState(0)

    const [usersFriendship, setUsersFriendship] = useState(0)

    const checkingUserNameFunction =(ownName, usernameFromLink, newInfoFromDatabase) => {
        //console.log('ownName', ownName)
        //console.log('usernameFromLink', usernameFromLink)
        //console.log('newInfoFromDatabase', newInfoFromDatabase)
        if(usernameFromLink){
            if (ownName != usernameFromLink) {
                check_existing_page(usernameFromLink)
            } else if(ownName == usernameFromLink){
                //console.log('it is own name')
                setExistingUserPage(1)
                setBlockedUserState(1)
                Data(usernameFromLink, newInfoFromDatabase)
                displayFriend(usernameFromLink)
                displayFriendRequest()
                setDisplayAddingInfo(0)
                setOwnInfo(0)
                setFriendsVisability(0)
            } 
        } else{
            setExistingUserPage(1)
            setBlockedUserState(1)
            Data(usernameFromLink, newInfoFromDatabase)
            displayFriend(usernameFromLink)
            displayFriendRequest()
            setDisplayAddingInfo(0)
            setOwnInfo(0)
            setFriendsVisability(0)
        }
    }

    const getSettingsInfoFunction =(usernameFromLinkData) => {
        const url = `${process.env.REACT_APP_BACKEND}/getSettingsInfo?usernameFromLinkData=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
                settingBooleanForFriendsVisabilityFunction(response.data.friends)
                settingBooleanForOwnInfoVisabilityFunction(response.data.own_info)
        });
    }

    const check_if_user_a_friend2 =(usernameFromLinkData) => {
        const url = `${process.env.REACT_APP_BACKEND}/checkFriendship?usernameFromLinkData=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
                //alert(response.data.message)
                if(response.data.message && response.data.message === 'True'){
                    setUsersFriendship(1)
                }else{
                    setUsersFriendship(0)
                }
        });
    }

    const check_if_user_a_friend =(usernameFromLinkData) => {
        const url = `${process.env.REACT_APP_BACKEND}/checkFriendship?usernameFromLinkData=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
                //alert(response.data.message)
                if(response.data.message && response.data.message === 'True'){
                    Data(usernameFromLink)
                    displayFriend(usernameFromLink)
                    setOwnInfo(0)
                    setUsersFriendship(1)
                }else{
                    setOwnInfo(1)
                    setUsersFriendship(0)
                }
        });
    }

    const check_if_user_a_friend3 =(usernameFromLinkData) => {
        const url = `${process.env.REACT_APP_BACKEND}/checkFriendship?usernameFromLinkData=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
                //alert(response.data.message)
                if(response.data.message && response.data.message === 'True'){
                    setFriendsVisability(0)
                    displayFriend(usernameFromLink)
                    setUsersFriendship(1)
                }else{
                    setFriendsVisability(1)
                    setUsersFriendship(0)
                }
        });
    }

    const settingBooleanForFriendsVisabilityFunction =(infoVisabilityDatabaseValue) => {
        //console.log('HERE IT IS', infoVisabilityDatabaseValue)
        if(infoVisabilityDatabaseValue === 'All the users'){
            Data(usernameFromLink)
            displayFriend(usernameFromLink)
            setFriendsVisability(0)                   
        }else if (infoVisabilityDatabaseValue === 'Only to my friends'){
            check_if_user_a_friend3(usernameFromLink)
        } else if (infoVisabilityDatabaseValue === 'Only to me'){
            setFriendsVisability(1)
        }
    }

    const settingBooleanForOwnInfoVisabilityFunction =(friendsVisabilityDatabaseValue) => {
        //console.log('HERE IT IS', friendsVisabilityDatabaseValue)
        if(friendsVisabilityDatabaseValue === 'All the users'){
            setOwnInfo(0)       
            Data(usernameFromLink)            
        }else if (friendsVisabilityDatabaseValue === 'Only to my friends'){
            check_if_user_a_friend(usernameFromLink)
        } else if (friendsVisabilityDatabaseValue === 'Only to me'){
            setOwnInfo(1)
        }
    }

    const editInformation =() => {
        setModal(1)
    }

    const [addFriendIndexType, setAddFriendIndexType] = useState(0)
    const addFriendIndex =() => {       
        setAddFriendX(1)
        setAddFriendIndexType(1)
    }

    const closeAddFriend =() => {
        setAddFriendX(0)
        setAddFriendIndexType(0)
    }

    const close =() => {
        setModal(0)
    }

    const addFriend =(name) => {
        setFriendName(name)
        axios.post(`${process.env.REACT_APP_BACKEND}/addFriend`, {
            name: name, 
        }).then((response) => {
            //console.log(response)
            //alert(response.data.message)
            displayFriend()
            displayFriendRequest()
            //setAlertState(1);
            //const descr = response.data.message
            //setDescription(descr)
            //setTimeout(() => {
                //setAlertState(0);
                //setDescription('')
              //}, 5000);
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const displayFriend =(usernameFromLinkData) => {
        //console.log('DATA USENAME', usernameFromLinkData)
        const url = `${process.env.REACT_APP_BACKEND}/displayFriend?usernameFromLink=${usernameFromLinkData}`;
        axios.get(url).then((response) => {
            //console.log('receiving data')
            //setPost(response.data);
            if (response.data.friendNames){
                setFriendNameList(response.data.friendNames)
            } 
        });
        
    }

    const sendFriendRequest =() => {
        axios.post(`${process.env.REACT_APP_BACKEND}/sendFriendRequest`, {
            name: friendNameRequest, 
        }).then((response) => {
            //alert(response.data.message)
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const sendFriendRequestFromUsersPage =() => {
        axios.post(`${process.env.REACT_APP_BACKEND}/sendFriendRequest`, {
            name: usernameFromLink, 
        }).then((response) => {
            //alert(response.data.message)
            setAlertState(1);
            const descr = response.data.message
            setDescription(descr)
            setTimeout(() => {
                setAlertState(0);
                setDescription('')
              }, 5000);
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const removeFriendFromUsersPage =() => {
        axios.post(`${process.env.REACT_APP_BACKEND}/removeFriend`, {
            name: usernameFromLink, 
        }).then((response) => {
            //alert(response.data.message)
            //setAlertState(1);
            //const descr = response.data.message
            //setDescription(descr)
            //setTimeout(() => {
                //setAlertState(0);
                //setDescription('')
              //}, 5000);
              setUsersFriendship(0)
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const displayFriendRequest =() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/displayFriendRequest`).then((response) => {
            //console.log('receiving data')
            //setPost(response.data);
            if (response.data.friendNames){
                setFriendNameListRequest(response.data.friendNames)
            } 
        });
        
    }

    const [image, setImage] = useState(null)
    const [imageIndex, setImageIndex] = useState(0)
    const [inputPhotoIndex, setInputPhotoIndex] = useState(0)
    const [fileError, setFileError] = useState(0);

    const [file, setFile] = useState()

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {

          const selectedFile = event.target.files[0];
          const fileSizeInKB = selectedFile.size / 1024;

            if (fileSizeInKB > 300) {
                setFileError(1)
                setAlertStateWrong(1);
                setDescription('File size should not exceed 300 KB')
                event.target.value = null; // Reset file input
                setImage(null); // Reset image preview
                setFile(null); // Reset file state
                return;
            }else{
                setImage(URL.createObjectURL(event.target.files[0]));
                setImageIndex(1)
                setInputPhotoIndex(1)
            }
        }

        const file = event.target.files[0]
            setFile(file)
    }

    const [styleButton, setStyleButton] = useState(0)

    const submit = async event => {
        event.preventDefault()
        setStyleButton(1)
    
        const formData = new FormData();
        formData.append("image", file)
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/posts`, formData, { headers: {'Content-Type': 'multipart/form-data'}})

        getPostsCall()
      }



    const removeRequest =(name) => {
        setFriendName(name)
        axios.post(`${process.env.REACT_APP_BACKEND}/removeRequest`, {
            name: name, 
        }).then((response) => {
            //console.log(response)
            //alert(response.data.message)
            displayFriendRequest()
            //setAlertState(1);
            //const descr = response.data.message
            //setDescription(descr)
            //setTimeout(() => {
                //setAlertState(0);
                //setDescription('')
              //}, 5000);
        }).catch((err) => {
            //console.log(err.message)
            //alert(JSON.stringify(err.response.data.message))
            setAlertStateWrong(1);
            const descrWrong = err.response.data.message
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
              }, 5000);
        })
        
    }

    const linkToTheUserPageFunction = (friend) => {
        //console.log('Here is the friend', friend)
        window.location.href = `/account?user=${friend}`;
    };

    const [randomRoom, setRandomRoom] = useState('')

    const checkIfPrivateChatExistsFunction = (friendNameFromLinkChat) => {
        checkIfPrivateChatExists(friendNameFromLinkChat)
    };

      
    const checkIfPrivateChatExists = (friendNameFromLinkChat) => {
        //console.log('FRIENDNAME IS', friendNameFromLinkChat)
        const url = `${process.env.REACT_APP_BACKEND}/checkIfPrivateChatExists?friendName=${friendNameFromLinkChat}`;
        axios.get(url).then((response) => {
          //console.log('Full axios response chats', response) 
            if (response.data.chat){
              //console.log('Receiving data chat', response.data.chat)
              const chatLink = (response.data.chat)
              const specialMessage = (response.data.specialMessage)
              generateRandomStringPrivateChat(friendNameFromLinkChat, chatLink, specialMessage)
            } else {
              console.log('No chat received or invalid data structure')
            }
        }) 
        .catch((error) => {
          console.error('Error', error.message)
        })
    } 

    const checkIfChatExists = async (initialRoomNumber) => {
        //console.log('checkIfChatExists', initialRoomNumber)
        const url = `${process.env.REACT_APP_BACKEND}/checkIfChatExistsRandomRoom?roomNumber=${initialRoomNumber}`;
        //console.log('url is', url)
        try {
            const response = await axios.get(url);
            //console.log('Full axios response chats', response);
            if (response.data.specialMessage) {
                //console.log('Receiving data chat', response.data.specialMessage);
                if (response.data.specialMessage === 'chatExists') {
                    //console.log('exists', response.data.specialMessage);
                    return true;
                } else {
                    //console.log('chat does not exist', response.data.specialMessage);
                    return false;
                }
            } else {
                return false; // Handle the case where specialMessage is not present
            }
        } catch (error) {
            console.error('Error', error.message);
            return false; // Return false or handle as needed in case of an error
        }
    } 

    const generateRandomStringPrivateChat = async (friendNameFromLinkChat, chatLink, specialMessage) => {
        //console.log('CHAT LINK IS', chatLink)
        //console.log('special message', specialMessage)
        if(chatLink != 'noChat'){
            //console.log('the chat here')
            if(specialMessage && specialMessage == 'one user in chat'){
                linkToOnePersonExistingChat(friendNameFromLinkChat, chatLink)
            } else{
                //console.log('THIS IS A LINK!', chatLink)
                linkToPrivateChat(chatLink)
            }
        }
        else{
              //console.log('CHAT LINK IS', chatLink)

              const newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36) + ':private';

              let numberExists = await checkIfChatExists(newRandomRoom);
              //console.log('numberExists 1', numberExists)
              while (numberExists) {
                  //console.log('number exists, generating new random room...');
                  newRandomRoom = Math.floor(Math.random() * Date.now()).toString(36);
                  //console.log('new random room:', newRandomRoom);
                  numberExists = await checkIfChatExists(newRandomRoom);    
                  //console.log('numberExists new', numberExists)
              }

              //console.log('final random room:', newRandomRoom);
              setRandomRoom(newRandomRoom);
              //setCode(newRandomRoom)
              //console.log('random room', newRandomRoom);
              linkToNewPrivateChat(friendNameFromLinkChat, newRandomRoom)
        }
    };

    const linkToOnePersonExistingChat =(friendNameFromLinkChat, privateChat) => {
        //window.location.href = `/joinchat?room=${privateChat}&friendName=${friendNameFromLinkChat}`;
        //console.log('Here is the room number', privateChat)
        setCode(privateChat)
        setFriendNameFromContext(friendNameFromLinkChat)
        navigate(`/joinchat?room=${privateChat}`)
    }

    const linkToPrivateChat =(privateChat) => {

        //console.log('Here is the room number', privateChat)
        //window.location.href = `/joinchat?room=${privateChat}`;
        navigate(`/joinchat?room=${privateChat}`)
    }
        
    const linkToNewPrivateChat =(friendNameFromLinkChat, privateChat) => {

        //console.log('Here is the room number', privateChat)
        //console.log('FRIEND NAME IS DATA TO SEND', friendNameFromLinkChat)
        //sendDataToParent(friendName)
        //window.location.href = `/joinchat?room=${privateChat}&friendName=${friendNameFromLinkChat}`;
        setCode(privateChat)
        setFriendNameFromContext(friendNameFromLinkChat)
        navigate(`/joinchat?room=${privateChat}&friendName=${friendNameFromLinkChat}`)
    }

    const [removeStyleButtons, setRemoveStyleButtons] = useState(0)
    const [threeDotsStyleChat, setThreeDotsStyleChat] = useState(0)

    const documentRef = useRef(document); 
        
    useEffect(() => {
        const handleClick = (event) => {
            const block = document.getElementById(`displayButtons`);
            const open = document.getElementById(`threeDotsIdChat`);
            //console.log('block is', block)
            //console.log('open is', open)
            if (open && open.contains(event.target)) {
                setRemoveStyleButtons(1)
                setThreeDotsStyleChat(1)
            } else if (block && !block.contains(event.target)) {
                setThreeDotsStyleChat(0)
                setRemoveStyleButtons(0)
            }
        };

        documentRef.current.addEventListener('click', handleClick);

        return () => {
            documentRef.current.removeEventListener('click', handleClick);
        };
    }, []);

    const [newNickNameError, setNewNickNameError] = useState('');
    const [newLevelError, setNewLevelError] = useState('');
    const [newAgeError, setNewAgeError] = useState('');
    const [newInfoError, setNewInfoError] = useState('');

    const validateNewNickName = (value) => {
        const regex = /^$|^[a-zA-Zа-яА-ЯёЁ0-9-_]+$/;
        if (value.includes(' ')) {
            setNewNickNameError('Username cannot contain spaces');
            return false;
        } else if (!regex.test(value)) {
            setNewNickNameError('Username can only contain cyrillic or latin letters, numbers, hyphens, and underscores');
            return false;
        } else if (value.length > 30) {
            setNewNickNameError('Maximum 30 characters allowed for username');
            return false;
        } else {
            setNewNickNameError('');
            return true;
        }
    };

    const validateNewLevel = (value) => {

        if (!/^[1-6]$/.test(value)) {
            setNewLevelError('Only numbers from 1 to 6 are allowed');
            return false;
        }else {
            setNewLevelError('');
            return true;
        }
    };

    const validateNewAge = (value) => {

        if (value === '') {
            setNewAgeError('');
            return true;
        } else if (!/^(100|[1-9][0-9]?)$/.test(value)) {
            setNewAgeError('Only numbers from 1 to 100 are allowed');
            return false;
        } else {
            setNewAgeError('');
            return true;
        }
    };

    const validateNewInfo = (value) => {
        if (value === '') {
            setNewInfoError('');
            return true;
        } else if (value.length < 2) {
            setNewInfoError('Information must contain at least two characters');
            return false;
        } else if (value.length > 400) {
            setNewInfoError('Maximum 400 characters allowed for information');
            return false;
        } else {
            setNewInfoError('');
            return true;
        }
    };

    const [blockedUserState, setBlockedUserState] = useState(0)

    const checkIfUserBlockedFunction = (friendNameFromLinkChat) => {
        //console.log('FRIENDNAME IS', friendNameFromLinkChat)
        const url = `${process.env.REACT_APP_BACKEND}/isBlockedUser?friendName=${friendNameFromLinkChat}`;
        axios.get(url).then((response) => {
            if (response.data.message === 'Blocked') {
                setBlockedUserState(2);  
                //console.log('MESSAGE', response.data.message)
            } else{
                //console.log('MESSAGE 2', response.data.message)
                setBlockedUserState(1);  
                setDisplayAddingInfo(1)
                DataNickName(usernameFromLink)
                getSettingsInfoFunction(usernameFromLink)
                check_if_user_a_friend2(usernameFromLink)
            }          
        }) 
        .catch((error) => {
        })
    } 

    const blockUserFunction = (friendNameFromLinkChat) => {
        setAskingBox(0)
        //console.log('FRIENDNAME IS', friendNameFromLinkChat)
        const url = `${process.env.REACT_APP_BACKEND}/blockUser?friendName=${friendNameFromLinkChat}`;
        axios.get(url).then((response) => {
                setAlertState(1);
                const descr = response.data.message
                setDescription(descr)
                setTimeout(() => {
                    setAlertState(0);
                    setDescription('')
                  }, 5000);
               setBlockedUserStateMessage(1)
        }) 
        .catch((error) => {
            setAlertStateWrong(1);
            const descrWrong = error.response.data
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
            }, 5000);
        })
    } 

    const unblockUserFunction = (friendNameFromLinkChat) => {
        //console.log('FRIENDNAME IS', friendNameFromLinkChat)
        const url = `${process.env.REACT_APP_BACKEND}/unblockUser?friendName=${friendNameFromLinkChat}`;
        axios.get(url).then((response) => {
                setAlertState(1);
                const descr = response.data.message
                setDescription(descr)
                setTimeout(() => {
                    setAlertState(0);
                    setDescription('')
                  }, 5000);
                  setBlockedUserStateMessage(0)
        }) 
        .catch((error) => {
            setAlertStateWrong(1);
            const descrWrong = error.response.data
            setDescription(descrWrong)
            setTimeout(() => {
                setAlertStateWrong(0);
                setDescription('')
            }, 5000);
        })
    } 

    const [blockedUserStateMessage, setBlockedUserStateMessage] = useState(0)

    const checkIfUserBlockedFunctionMessage = (friend) => {
        const url = `${process.env.REACT_APP_BACKEND}/isBlockedUser?friendName=${friend}`;
        axios.get(url).then((response) => {
            if (response.data.message === 'Blocked') {
                setBlockedUserStateMessage(1)
            } else if (response.data.message === 'Blocked yourself') {
                setBlockedUserStateMessage(1)
            }         
        }) 
        .catch((error) => {
        })
    } 

    const [askingBox, setAskingBox] = useState(0)

    const blockUserFunctionOn = () => {
        setAskingBox(1)
      };

    const blockUserFunctionOff = () => {
        setAskingBox(0)
      };

      const [posts, setPosts] = useState([])

      useEffect(() => {
        async function getPosts() {
          const result = await axios.get(`${process.env.REACT_APP_BACKEND}/api/posts?usernameFromLink=${usernameFromLink}`)
          setPosts(result.data)
          if(result.data.length  != 0){
            setInputPhotoIndex(1)
            setImageIndex(1)
          }
        }
        getPosts()
      }, [])

      async function getPostsCall() {
        const result = await axios.get(`${process.env.REACT_APP_BACKEND}/api/posts?usernameFromLink=${usernameFromLink}`)
        setPosts(result.data)
        setStyleButton(0)
      }

      const deletePostClicked = async (id) => {
        //console.log(`deletePostClicked = (${id})`);
        setImageIndex(0)
        setInputPhotoIndex(0)
        setImage(null)
        setFile(null)
        setPosts([])
        try {
            await axios.get(`${process.env.REACT_APP_BACKEND}/api/posts/deletePhoto?id=${id}`);
            setPosts(posts.filter(post => post.id !== id));
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    return (
        <>
        {existingUserPage === 1 && blockedUserState === 1 ? 
        <div className="myAccountPage">
        <div className="myAccount">
            <div className="photoDiv">
            <div className="photo">
                <label htmlFor="file" className={ inputPhotoIndex === 0 ? "inputPhoto" : "inputPhoto2" }>Photo</label>
                <input id="file" className="inputPhotoText"></input>
                { imageIndex === 0 ? <div className="imageForAccount"></div> :
               (posts.length != 0 ? 
                <>
                    {posts.map(post => (
                        <div key={`post-${post.id}`}>

                        <div className={post.imageUrl ? "" : "divForPostPhoto"}>
                        {post.imageUrl && <img src={post.imageUrl} alt={post.title} className="divForPostPhoto" />}
                        </div>

                        <button onClick={() => deletePostClicked(post.id)} className={modal === 0 ? "notDisplayed" : "deletePhotoButton"}><div className="removeWordPhoto">Remove</div> <div>photo</div></button>
                        </div>
                    ))}
                </> :
                <>
                <img className={ imageIndex === 0 ? "imageForAccount" : "imageForAccount2" } src={image}/>
                </>
            )
            }
            </div>
            <form className={modal === 0 ? "notDisplayed" : (posts.length != 0 ? "notDisplayed" : "buttonAbsolute")} onSubmit={submit}>
                <label htmlFor="chooseFileText" className="chooseFileTextLabel">Choose file</label>
                <input id="chooseFileText" className="chooseFileText" onChange={onImageChange} type="file" accept="image/*"></input>
                <button className="changePhotoButton" type="submit" style={styleButton === 1 ? { cursor: 'not-allowed' } : {}} disabled={styleButton === 1 || imageIndex === 0}>Save</button>
            </form>   
            </div>     
       
            <div className="information">
                <div className={ modal === 0 ? "editModal1" : "editModal" }>
                <p className="informationContent">NickName: <span className="informationContentInfo">{nickName}</span></p>
                <p className="informationContent">HSK: <span className="informationContentInfo">{ownInfo === 0 ? (level ? level : '...') : '...'}</span></p>
                <p className="informationContent">Age: <span className="informationContentInfo">{ownInfo === 0 ? (age ? age : '...') : '...'}</span></p>
                <p className="informationContent">About me: <span className="informationContentInfo">{ownInfo === 0 ? (info ? info : '...') : '...'}</span></p>
                <button type="button" className={ displayAddingInfo === 0 ? "buttonFormSubmit2MyAccount" : "notDisplayed" } onClick={() => {editInformation()}}>Edit</button>
                </div>

                <div className={ modal === 0 ? "editModal" : "editModalColumn" }>
                <button className={ modal === 0 ? "editModal" : "turnX" } onClick={() => {close()}}>X</button>
                <div className={ modal === 0 ? "editModal" : "editModal2" }>
                    
                <div>
                <p className="informationContent">NickName:</p>

                <p className="informationContentLevel">HSK:</p>

                <p className="informationContentAge">Age:</p>

                <p className="informationContent">About me:</p>
                </div>
                <form className="formAccountEdit">
                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={nickName}
                        onChange={(e) => {
                            setNewNickName(e.target.value)
                            validateNewNickName(e.target.value);
                    }}/>
                    {newNickNameError && <span className="errorEditMyAccountInfo">{newNickNameError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={level}
                        onChange={(e) => {
                            setNewLevel(e.target.value)
                            validateNewLevel(e.target.value);
                    }}/>
                    {newLevelError && <span className="errorEditMyAccountInfo">{newLevelError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={age}
                        onChange={(e) => {
                            setNewAge(e.target.value)
                            validateNewAge(e.target.value);
                    }}/>
                    {newAgeError && <span className="errorEditMyAccountInfo">{newAgeError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <textarea type="text" className="buttonFormAccountInfoTextArea" placeholder={info}
                        onChange={(e) => {
                            setNewInfo(e.target.value)
                            validateNewInfo(e.target.value);
                    }}>
                </textarea>
                {newInfoError && <span className="errorEditMyAccountInfoTextArea">{newInfoError}</span>}
                </div>

                <button type="button" className="buttonFormSubmitSave" onClick={() => {edit()}} disabled={newNickNameError || newLevelError || newAgeError || newInfoError}>Save</button>
            </form>
            </div>
            </div>
        </div>

        <button className={threeDotsStyleChat === 0 ? (displayAddingInfo === 0 ? "notDisplayed" : "threeDotsRemoveButtonChatMyAccount") : "threeDotsRemoveButtonOnClick"} id="threeDotsIdChat">
                          <div className="blackDotRemoveButtonMyAccount"></div>
                          <div className="blackDotRemoveButtonMyAccount"></div>
                          <div className="blackDotRemoveButtonMyAccount"></div>
        </button>

        {blockedUserStateMessage === 0 ? 
        <div className={ removeStyleButtons === 0 ? "notDisplayed" : (displayAddingInfo === 0 ? "notDisplayed" : "otherUserPageAbsoluteDiv") } id="displayButtons">
        {usersFriendship === 0 ? 
        <svg className="svgCursorPointer" onClick={sendFriendRequestFromUsersPage} xmlns="http://www.w3.org/2000/svg" fill="#000000" width="25px" height="25px" viewBox="0 0 24 24"><path d="M2,21h8a1,1,0,0,0,0-2H3.071A7.011,7.011,0,0,1,10,13a5.044,5.044,0,1,0-3.377-1.337A9.01,9.01,0,0,0,1,20,1,1,0,0,0,2,21ZM10,5A3,3,0,1,1,7,8,3,3,0,0,1,10,5ZM23,16a1,1,0,0,1-1,1H19v3a1,1,0,0,1-2,0V17H14a1,1,0,0,1,0-2h3V12a1,1,0,0,1,2,0v3h3A1,1,0,0,1,23,16Z"/>
        </svg>
        :
        <svg className="svgCursorPointer" onClick={removeFriendFromUsersPage} xmlns="http://www.w3.org/2000/svg" fill="#000000" width="25px" height="25px" viewBox="0 0 24 24"><path d="M2,21h8a1,1,0,0,0,0-2H3.071A7.011,7.011,0,0,1,10,13a5.044,5.044,0,1,0-3.377-1.337A9.01,9.01,0,0,0,1,20,1,1,0,0,0,2,21ZM10,5A3,3,0,1,1,7,8,3,3,0,0,1,10,5ZM23,16a1,1,0,0,1-1,1H15a1,1,0,0,1,0-2h7A1,1,0,0,1,23,16Z"/>
        </svg>
        }
        <svg className="svgCursorPointer" onClick={() => {checkIfPrivateChatExistsFunction(usernameFromLink)}} xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
        <path d="M10 9H17M10 13H17M7 9H7.01M7 13H7.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <svg className="svgCursorPointer" onClick={() => {blockUserFunctionOn()}}xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
        <path d="M5.63605 5.63603L18.364 18.364M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
        :
        <div className={ removeStyleButtons === 0 ? "notDisplayed" : (displayAddingInfo === 0 ? "notDisplayed" : "otherUserPageAbsoluteDivUnblockUser") } id="displayButtons">
        <svg className="svgCursorPointer" onClick={() => {unblockUserFunction(usernameFromLink)}} xmlns="http://www.w3.org/2000/svg" width="27px" height="27px" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.63604 18.364C9.15076 21.8787 14.8492 21.8787 18.364 18.364C21.8787 14.8492 21.8787 9.15076 18.364 5.63604C14.8492 2.12132 9.15076 2.12132 5.63604 5.63604C2.12132 9.15076 2.12132 14.8492 5.63604 18.364ZM7.80749 17.6067C10.5493 19.6623 14.4562 19.4433 16.9497 16.9497C19.4433 14.4562 19.6623 10.5493 17.6067 7.80749L14.8284 10.5858C14.4379 10.9763 13.8047 10.9763 13.4142 10.5858C13.0237 10.1953 13.0237 9.5621 13.4142 9.17157L16.1925 6.39327C13.4507 4.33767 9.54384 4.55666 7.05025 7.05025C4.55666 9.54384 4.33767 13.4507 6.39327 16.1925L9.17157 13.4142C9.5621 13.0237 10.1953 13.0237 10.5858 13.4142C10.9763 13.8047 10.9763 14.4379 10.5858 14.8284L7.80749 17.6067Z" fill="#000000"/>
        </svg>
        </div>}

        <div className={askingBox === 0 ? "askingBoxNone" : "askingBox"}>
                <p className="pRemove">Are you sure you want to block the user?</p>
                <div className="answerRemove">
                    <button className="yesAnswer" onClick={() => {blockUserFunction(usernameFromLink)}}>Yes</button>
                    <button className="noAnswer" onClick={() => {blockUserFunctionOff()}}>No</button>
                </div>
        </div>
    </div>

    <div className={ modal === 0 ? "editModal" : "editModalColumnResponsive" }>
                <button className={ modal === 0 ? "editModal" : "turnX" } onClick={() => {close()}}>X</button>
                <div className={ modal === 0 ? "editModal" : "editModal2" }>
                    
                <div>
                <p className="informationContent">NickName:</p>

                <p className="informationContentLevel">HSK:</p>

                <p className="informationContentAge">Age:</p>

                <p className="informationContent">About me:</p>
                </div>
                <form className="formAccountEdit">
                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={nickName}
                        onChange={(e) => {
                            setNewNickName(e.target.value)
                            validateNewNickName(e.target.value);
                    }}/>
                    {newNickNameError && <span className="errorEditMyAccountInfo">{newNickNameError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={level}
                        onChange={(e) => {
                            setNewLevel(e.target.value)
                            validateNewLevel(e.target.value);
                    }}/>
                    {newLevelError && <span className="errorEditMyAccountInfo">{newLevelError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <input type="text" className="buttonFormAccountInfo" placeholder={age}
                        onChange={(e) => {
                            setNewAge(e.target.value)
                            validateNewAge(e.target.value);
                    }}/>
                    {newAgeError && <span className="errorEditMyAccountInfo">{newAgeError}</span>}
                </div>

                <div className="buttonFormAccountInfoDiv">
                <textarea type="text" className="buttonFormAccountInfoTextArea" placeholder={info}
                        onChange={(e) => {
                            setNewInfo(e.target.value)
                            validateNewInfo(e.target.value);
                    }}>
                </textarea>
                {newInfoError && <span className="errorEditMyAccountInfoTextArea">{newInfoError}</span>}
                </div>

                <button type="button" className="buttonFormSubmitSave" onClick={() => {edit()}} disabled={newNickNameError || newLevelError || newAgeError || newInfoError}>Save</button>
            </form>
            </div>
            </div>

            <div className="friendsAddingAndListCommon">
            <div className={ displayAddingInfo === 0 ? "friendsAddingAndList" : "notDisplayed" }>
                <div className="row">
                <button className={ addFriendX === 0 ? "addFriendText" : "addFriendText2" } onClick={() => {addFriendIndex()}}>Add a friend</button>   
                <button className={ addFriendX === 0 ? "notDisplayed" : "turnXAddFriend" } onClick={() => {closeAddFriend()}}>X</button>           
                </div>
                <form className={ addFriendIndexType === 0 ? "notDisplayed" : "addFriend" }>
                <p className="typeUsername">Type username </p>
                <div>
                    <input type="text" className="buttonForm" 
                        onChange={(e) => {
                            setFriendNameRequest(e.target.value)
                    }}/>
                </div>
                

                <button type="button" className="buttonFormSubmit2" onClick={() => {sendFriendRequest()}}>Add</button>
                </form>
                            
            </div>
            </div>

            <div className="friendsAddingAndListCommonP">
            <p className={ friendsVisability === 0 ? "nameText" : "notDisplayed" }>Friends: </p>
            </div>

            <div className={ friendsVisability === 0 ? (displayAddingInfo === 0 ? "friendsList" : "friendsListMargin") : "notDisplayed" }> 
                        {
                            friendNameList.map((name, index) => {
                                return (
                                    <div key={index} className="friendAccountLink" onClick={() => {linkToTheUserPageFunction(name.friendName)}}> 
                                    
                                    {name.friendsPhoto.length != 0 ?
                                    <div>
                                             {name.friendsPhoto.map(post => (
                                                <div key={`post-${post.id}`} className="friendsPhoto">

                                                {post.imageUrl && <img src={post.imageUrl} alt={post.title} className="friendsPhoto" />}
                                               
                                                </div>
                                            ))}
                                    </div> 
                                    :
                                    <div className="photoFriendsList">
                                     <svg className="svgAccount" xmlns="http://www.w3.org/2000/svg" fill="#000000" width="40px" height="40px" viewBox="0 0 32 32">
                                        <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"/>
                                     </svg>
                                     </div>
                                     }
        
                                    <div className="nameAccount">{name.friendName} </div>
                                    <br/> 
                                    </div>
                                )
                            })
                        }
            </div>

            <div className={ displayAddingInfo === 0 ? "friendsRequest" : "notDisplayed" }>
                <div>
                        {
                            friendNameListRequest.map((name) => {
                                return (
                                    <div className="friendsNameRequests"> 
                                    <button className="buttonFormConfirmRequest" onClick={() => {addFriend(name)}}>Add</button>
                                    <div className="friendNameInRequest" onClick={() => {linkToTheUserPageFunction(name)}}>{name}</div> <br/> 
                                    <button className="removeRequest" onClick={() => {removeRequest(name)}}>X</button>
                                    </div>
                                )
                            })
                        }
                </div>
            </div>

            <div className={alertState ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="check">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="#ffffff" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Success</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className="progressDiv active"></div>
                </div>


                <div className={alertStateWrong ? 'alertDiv' : 'notDisplayed' }>
  
                <div className="alertDiv-content">
                    <i className="checkWrong">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path d="M6 12L18 12" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </i>

                    <div className="messageDiv">
                    <span className="textDiv textDiv-1">Ops</span>
                    <span className="textDiv textDiv-2">{description}</span>
                    </div>
                </div>
                <button className="close" onClick={() => {closeAlert()}}>X</button>

                <div className={fileError ? "notDisplayed" : "progressDiv active"}></div>
                </div>
        </div>
         :  
         (existingUserPage === 2 || blockedUserState === 2 ? <NotFoundPage /> : <><div className="main"></div></>)        
         }
        </>
    )

  }


