import { createContext, useState, useContext } from 'react';
import React from 'react';

export const FriendNameContext = createContext({friendNameFromContext: '--', setFriendNameFromContext: () => {}})

export const FriendNameProvider = ({ children }) => {
    const [friendNameFromContext, setFriendNameFromContext] = useState('')

    const updateCode = (newCode) => {
        setFriendNameFromContext(newCode)
    }

    return (
        <FriendNameContext.Provider value={{ friendNameFromContext, setFriendNameFromContext: updateCode}}>
            {children}
        </FriendNameContext.Provider>
    )
}